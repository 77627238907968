import { Button } from 'components/_universal/Button/Button';
import styled, { css } from 'styled-components';

export const TableWrapper = styled.div<{ isSafari?: boolean }>(
    ({ theme: { colors, fontSizes, fontWeight, fontFamily }, isSafari }) => css`
        width: 100%;
        height: 100%;
        z-index: 1;
        background: #ffffff;
        box-shadow: 0px 0px 15px rgba(84, 84, 84, 0.3);
        border-radius: 35px;
        position: relative;

        table {
            width: 100%;
            border-spacing: 0;
            thead {
                position: relative;

                th {
                    border-bottom: 1px solid ${colors.profileBorder};
                    border-top: 1px solid ${colors.profileBorder};
                    font-family: ${fontFamily.secondary};
                    font-size: ${fontSizes.f13};
                    font-weight: ${fontWeight.regular};
                    line-height: 30px;
                    padding: 12px;
                    position: relative;
                    text-align: left;
                    white-space: nowrap;

                    &:first-child {
                        padding-left: 32px;
                    }

                    &:last-child {
                        * {
                            display: none;
                        }
                    }
                    svg {
                        margin-left: 4px;
                    }
                }
            }

            tbody {
                ${isSafari && 'transform: translate(0);'}
                position: relative;

                th,
                td {
                    background-color: ${colors.white};
                    border-bottom: 1px solid ${colors.profileBorder};
                    border-right: 0;
                    font-size: ${fontSizes.f12};
                    font-weight: ${fontWeight.light};
                    font-family: ${fontFamily.secondary};
                    line-height: 22px;
                    margin: 0;
                    padding: 10px 12px;
                    vertical-align: middle;
                    white-space: nowrap;

                    &:first-child {
                        padding-left: 32px;
                    }
                }
            }
        }

        .pagination {
            display: flex;
            gap: 6px;
            justify-content: flex-end;
            list-style-type: none;
            padding: 10px 48px;

            li {
                align-items: center;
                border-radius: 100px;
                border: 1px solid ${colors.paginationBorder}50;
                color: ${colors.text};
                cursor: pointer;
                display: flex;
                font-family: ${fontFamily.secondary};
                font-size: ${fontSizes.f9};
                height: 30px;
                justify-content: center;
                line-height: 22px;
                width: 30px;
            }

            a {
                width: 100%;
                text-align: center;
            }

            .break {
                font-size: ${fontSizes.f14};
            }

            .previous,
            .break,
            .next {
                border: none !important;
            }

            &__link {
                &--active {
                    background: ${colors.primary}50;
                    border: none !important;
                }
                &--disabled {
                    display: none;
                }
            }
        }
    `,
);

export const FiltersShadow = styled.div<{
    pagination?: boolean;
    isSafari?: boolean;
}>(
    ({ pagination, isSafari }) =>
        css`
            background: rgba(84, 84, 84, 0.36);
            border-radius: ${pagination ? '0 0 35px 35px' : '0'};
            height: ${isSafari ? 'calc(100% - 56px)' : '100%'};
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 100;
        `,
);

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .select {
        padding: 10px 48px;
    }
`;

export const AddAudioButtonWrapper = styled.div`
    align-self: flex-start;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    padding: 14px;
`;

export const StyledButton = styled(Button)`
    background: linear-gradient(90deg, #01c5b9 0%, #019a91 100%);
    min-width: 165px;
`;

export const AddRecordingWrapper = styled.div`
    display: flex;
    gap: 12px;
`;
