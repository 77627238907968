import { notify } from 'components/ToastNotification/ToastNotification';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { Tooltip } from 'components/_universal/Tooltip/Tooltip';
import { RotatingLines } from 'react-loader-spinner';
import {
    TranscriptLoadStatus,
    TranscriptVerificationStatus,
    VerificationStatus,
    useRequestTranscriptMutation,
} from 'src/graphql/generated';
import {
    setIsTrascriptionModal,
    setRecordingInfo,
} from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { ToastTypes } from 'types/CommonTypes';
import { TranscriptionWrapper } from './Transcription.styled';

export interface IProps {
    transcriptLoadStatus: TranscriptLoadStatus;
    recordingId: number;
    recordingStatus: VerificationStatus;
    recordingName: string;
    transcriptVerificationStatus: TranscriptVerificationStatus;
    wholeTranscriptVerifiedBy: {
        avatar: string;
        email: string;
        id: number;
        name: string;
        role: string;
    };
}

export const Transcription = ({
    recordingId,
    recordingName,
    recordingStatus,
    transcriptLoadStatus,
    transcriptVerificationStatus,
    wholeTranscriptVerifiedBy,
}: IProps) => {
    const [requestTranscript] = useRequestTranscriptMutation();

    const onSubmit = () => {
        try {
            requestTranscript({
                variables: { recordingId: recordingId },
                onError: (error) => {
                    return notify('Zażądano transkrypcji: ' + error.message)[
                        ToastTypes.ERROR
                    ]();
                },
                refetchQueries: 'active',
            });
        } catch (error) {
            return notify('Coś poszło nie tak: ' + error)[ToastTypes.ERROR]();
        }
    };

    return (
        <TranscriptionWrapper>
            {transcriptLoadStatus === 'NOT_REQUESTED' && (
                <Tooltip text={'Generuj transkrypcję'}>
                    <IconManager
                        cursorPointer
                        name={'GenerateIcon'}
                        onClick={() => onSubmit()}
                    />
                </Tooltip>
            )}
            {transcriptLoadStatus === 'PENDING' && (
                <RotatingLines
                    strokeColor="#545454"
                    strokeWidth="2.5"
                    width="26.5"
                />
            )}
            {transcriptLoadStatus === 'LOADED' && (
                <Tooltip
                    text={
                        transcriptVerificationStatus === 'VERIFIED'
                            ? 'Transkrypcja zweryfikowana'
                            : 'Transkrypcja gotowa do weryfikacji'
                    }
                >
                    <IconManager
                        cursorPointer
                        name={'TableTranscriptionIcon'}
                        onClick={() => {
                            dispatch(
                                setRecordingInfo({
                                    id: recordingId,
                                    name: recordingName,
                                    status: recordingStatus,
                                    wholeTranscriptVerifiedBy:
                                        wholeTranscriptVerifiedBy,
                                }),
                            );
                            dispatch(setIsTrascriptionModal(true));
                        }}
                    />
                </Tooltip>
            )}
        </TranscriptionWrapper>
    );
};
