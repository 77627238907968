import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

export const StyledTextarea = styled.textarea<{ displayInlineFlex?: boolean }>(
    ({
        displayInlineFlex,
        theme: { colors, fontFamily, fontSizes, borderRadius },
    }) => css`
        background: transparent;
        border-radius: ${borderRadius.default}px;
        border: 1px solid ${colors.formBlue};
        color: ${colors.text};
        display: ${displayInlineFlex ? 'inline-flex' : 'flex'};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        line-height: 30px;
        margin: 4px;
        padding: 0 6px;
        resize: none;
        width: 100%;
        height: auto;
        min-height: max-content;
        overflow-y: hidden;
    `,
);

interface AutoResizeTextareaProps {
    name: string;
    register: any;
    defaultValue?: string;
    placeholder?: string;
}

const AutoResizeTextarea: React.FC<AutoResizeTextareaProps> = ({
    name,
    register,
    defaultValue = '',
    placeholder = '',
}) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [defaultValue]);

    return (
        <StyledTextarea
            ref={textareaRef}
            defaultValue={defaultValue}
            placeholder={placeholder}
            {...register(name)}
            onInput={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
            }}
            onClick={(e) => {
                const target = e.target as HTMLTextAreaElement;
                target.style.height = 'auto';
                target.style.height = `${target.scrollHeight}px`;
            }}
        />
    );
};

export default AutoResizeTextarea;
