import { EventMessage, EventType } from 'types/CommonTypes';

export const getNotificationMessage = (eventMessage: EventMessage): string => {
    const baseMessage = `Nagranie '${eventMessage.message.metadata?.fileName}' `;

    switch (eventMessage.type) {
        case EventType.RECORDING_CREATED:
            return `${baseMessage} zostało utworzone.`;
        case EventType.RECORDING_VERIFIED:
            return `${baseMessage} zostało zweryfikowane.`;
        case EventType.TRANSCRIPT_REQUESTED:
            return `${baseMessage} żądano transkrypcji.`;
        case EventType.TRANSCRIPT_VERIFIED:
            return `${baseMessage} transkrypcja została zweryfikowana.`;
        case EventType.TRANSCRIPT_GENERATED:
            return `${baseMessage} transkrypcja została wygenerowana.`;
        case EventType.REPORT_REQUESTED:
            return `${baseMessage} żądano formularza.`;
        case EventType.REPORT_GENERATED:
            return `${baseMessage} formularz został wygenerowany.`;
        default:
            return `${baseMessage} Otrzymano nieznane zdarzenie.`;
    }
};
