import React from 'react';
import { CheckboxStyled, CheckboxWrapper, Label } from './Checkbox.styled';
import { IconManager } from 'components/_universal/IconManager/IconManager';

interface CheckboxProps {
    checked: boolean;
    disabled?: boolean;
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
    checked,
    disabled,
    label,
    onChange,
}) => {
    return (
        <CheckboxWrapper disabled={disabled}>
            <IconManager
                name="CheckboxIcon"
                fill={checked ? ['formBlue'] : ['placeholder']}
            />
            <CheckboxStyled
                type="checkbox"
                checked={checked}
                onChange={onChange}
                disabled={disabled}
            />
            <Label>{label}</Label>
        </CheckboxWrapper>
    );
};

export default Checkbox;
