import { IconManager } from 'components/_universal/IconManager/IconManager';
import React, { useEffect } from 'react';
import { Divider, List, SectionSubtitle, StyledInput } from '../../Form.styled';
import { RecommendationFields } from '../../types';
import { RemoveButtonWrapper, SymptomsWrapper } from '../Styles';

interface NewRecommendationProps {
    index: number;
    recommendation: RecommendationFields;
    onChange: (index: number, field: string, value: any) => void;
    onRemove: (index: number) => void;
    isEdit: boolean;
}

const NewRecommendation: React.FC<NewRecommendationProps> = ({
    index,
    recommendation,
    onChange,
    onRemove,
    isEdit,
}) => {
    const handleItemChange =
        (idx: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newItems = [...recommendation.items];
            newItems[idx] = e.target.value;
            onChange(index, 'items', newItems);
        };

    const handleMedicationChange =
        (idx: number) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const newMedications = [...recommendation.medications];
            newMedications[idx] = e.target.value;
            onChange(index, 'medications', newMedications);
        };

    useEffect(() => {
        if (
            recommendation.items.length === 0 ||
            recommendation.items[recommendation.items.length - 1] !== ''
        ) {
            onChange(index, 'items', [...recommendation.items, '']);
        }

        if (
            recommendation.medications.length === 0 ||
            recommendation.medications[
                recommendation.medications.length - 1
            ] !== ''
        ) {
            onChange(index, 'medications', [...recommendation.medications, '']);
        }
    }, [recommendation, index, onChange]);

    return (
        <SymptomsWrapper isEdit={isEdit}>
            {index > 0 && <Divider marginTB={15} />}
            <List>
                {recommendation.items.map((item, idx) => (
                    <StyledInput
                        key={idx}
                        value={item}
                        onChange={handleItemChange(idx)}
                        placeholder={'Dodaj zalecenia'}
                    />
                ))}
            </List>
            <SectionSubtitle>Leki i dawkowanie</SectionSubtitle>
            <List>
                {recommendation.medications.map((medication, idx) => (
                    <StyledInput
                        key={idx}
                        value={medication}
                        onChange={handleMedicationChange(idx)}
                        placeholder={'Dodaj leki i dawkowanie'}
                    />
                ))}
            </List>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewRecommendation;
