import { SpeakerButton } from 'src/domains/MainPanel/components/SpeakerButton/SpeakerButton';
import { Transcription } from './components/Transcription/Transcription';
import { Form } from './components/Form/Form';
import { Status } from './components/Status/Status';
import { TranscriptionStatus } from './components/TranscriptionStatus/TranscriptionStatus';
import { Search } from './components/TableFilters/Search/Search';
import { DateFilters } from './components/TableFilters/DateFilters/DateFilters';
import { AudioRecordingFilters } from './components/TableFilters/AudioRecordingFilters/AudioRecordingFilters';
import { AudioStatusFilters } from './components/TableFilters/AudioStatusFilters/AudioStatusFilters';
import { TranscriptionStatusFilters } from './components/TableFilters/TranscriptionStatusFilters/TranscriptionStatusFilters';

export const columns = [
    {
        Header: <Search />,
        accessor: 'name',
    },
    {
        Header: <DateFilters />,
        accessor: 'createdAt',
    },
    {
        Header: <AudioRecordingFilters />,
        id: 'audio',
        Cell: ({ row }: any) => {
            return (
                <SpeakerButton
                    audioUrl={row?.original?.audioUrl}
                    comment={row?.original?.audioComment}
                    commentUserInfo={row?.original?.profileAudio}
                    recordingId={row?.original?.recordingId}
                    recordingName={row?.original?.name}
                    recordingStatus={row?.original?.recordingStatus}
                />
            );
        },
    },
    {
        Header: <AudioStatusFilters />,
        accessor: 'audioStatus',
        Cell: ({ row }: any) => {
            return (
                <Status
                    commentIcon={row?.original?.audioComment}
                    personAudio={row?.original?.profileAudio}
                    status={row?.original?.recordingStatus}
                />
            );
        },
    },
    {
        Header: 'Transkrypcja',
        accessor: 'transcryption',
        Cell: ({ row }: any) => {
            return (
                <Transcription
                    recordingId={row?.original?.recordingId}
                    recordingName={row?.original?.name}
                    recordingStatus={row?.original?.recordingStatus}
                    transcriptLoadStatus={row?.original?.transcriptLoadStatus}
                    wholeTranscriptVerifiedBy={
                        row?.original?.wholeTranscriptVerifiedBy
                    }
                    transcriptVerificationStatus={
                        row?.original?.transcriptVerificationStatus
                    }
                />
            );
        },
    },
    {
        Header: <TranscriptionStatusFilters />,
        accessor: 'transcriptStatus',
        Cell: ({ row }: any) => {
            return (
                <TranscriptionStatus
                    isWholeTranscriptVerified={
                        row?.original?.isWholeTranscriptVerified
                    }
                    transcriptVerificationStatus={
                        row?.original?.transcriptVerificationStatus
                    }
                    wholeTranscriptVerifiedBy={
                        row?.original?.wholeTranscriptVerifiedBy
                    }
                />
            );
        },
    },
    {
        Header: 'Formularz',
        accessor: 'form',
        id: 'form',
        Cell: ({ row }: any) => {
            return (
                <Form
                    hasReport={row?.original?.hasReport}
                    isReportQueued={row?.original?.isReportQueued}
                    recordingId={row?.original?.recordingId}
                />
            );
        },
    },
];
