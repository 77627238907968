import styled, { css } from 'styled-components';
import { ColorType, FontSizes, FontWeight } from 'types/CommonTypes';

export interface ITextProps {
    center?: boolean;
    color?: ColorType;
    fontSize?: FontSizes;
    fontStyle?: boolean;
    fontWeight?: FontWeight;
    italic?: boolean;
}

export const Title = styled.h1<ITextProps>(
    ({ theme: { fontFamily, fontSizes }, fontSize }) => css`
        font-family: ${fontFamily.secondary};
        font-size: ${fontSize ? fontSizes[fontSize] : fontSizes.f36};
        line-height: 36px;
        margin: 0;
        text-align: center;
    `,
);

export const SubTitle = styled.h2<ITextProps>(
    ({ theme: { fontFamily, fontSizes }, fontSize }) => css`
        font-family: ${fontFamily.primary};
        font-size: ${fontSize ? fontSizes[fontSize] : fontSizes.f24};
        line-height: 24px;
        margin: 0;
        text-align: center;
    `,
);

export const repeatableStyles = css<ITextProps>`
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.text};
    font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
    font-weight: ${({ theme, fontWeight }) =>
        fontWeight ? theme.fontWeight : theme.fontWeight.regular};
    text-align: ${({ center }) => (center ? 'center' : 'left')};
`;

export const DefaultText = styled.p<ITextProps>`
    ${repeatableStyles};
    font-size: ${({ theme, fontSize }) =>
        fontSize ? theme.fontSizes[fontSize] : theme.fontSizes.f12};
    margin: 0;
`;

export const LinkText = styled(DefaultText)`
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.secondary};
    font-size: ${({ theme, fontSize }) =>
        fontSize ? theme.fontSizes[fontSize] : theme.fontSizes.f14};
`;

export const LinkWrapper = styled.span<ITextProps>`
    a {
        ${repeatableStyles};

        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;
