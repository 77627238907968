import { useState } from 'react';
import { RadioContainer, RadioOption } from './RadioButton.styled';

export interface RadioOptionType {
    label: string;
    value: number;
}

export interface RadioButtonProps {
    disabled?: boolean;
    name: string;
    onChange: (value: number) => void;
    options: RadioOptionType[];
    selectedValue: number;
}

export const RadioButton = ({
    disabled,
    name,
    onChange,
    options,
    selectedValue,
}: RadioButtonProps) => {
    const [value, setValue] = useState<number>(selectedValue);

    return (
        <RadioContainer>
            {options.map((option) => (
                <RadioOption key={option.value} disabled={disabled}>
                    {option.label}
                    <input
                        disabled={disabled}
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={value === option.value}
                        onChange={() => {
                            onChange(option.value);
                            setValue(option.value);
                        }}
                    />
                </RadioOption>
            ))}
        </RadioContainer>
    );
};
