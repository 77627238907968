import { DefaultText, SubTitle } from 'components/_universal/Typography.styled';
import styled, { css } from 'styled-components';

export const FormWrapper = styled.div`
    background: rgba(41, 45, 50, 0.6);
    height: 100%;
    left: 0;
    padding: 105px 90px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10000;
`;

export const FormBox = styled.form(
    ({ theme: { colors } }) => css`
        background: ${colors.app_bg};
        border-radius: 25px;
        height: 100%;
        padding: 26px 72px;
        position: relative;
        width: 100%;
        overflow: auto;
    `,
);

export const Spacing = styled.div<{ height?: number }>(
    ({ height }) =>
        css`
            height: ${height}px;
        `,
);

export const CloseIconWrapper = styled.div`
    position: absolute;
    right: 26px;
    top: 26px;
`;

export const FormHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FormBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

export const FormTitle = styled(SubTitle)(
    ({ theme: { fontFamily, fontWeight } }) =>
        css`
            font-family: ${fontFamily.secondary};
            font-weight: ${fontWeight.regular};
        `,
);

export const FormInfoWrapper = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: flex-start;
`;

export const FormInfoInnerWrapper = styled.div`
    display: inline-flex;
    gap: 4px;
    line-height: 22px;
`;

export const FormInfoBlueText = styled(DefaultText)(
    ({ theme: { colors, fontFamily } }) =>
        css`
            color: ${colors.formBlue};
            font-family: ${fontFamily.secondary};
        `,
);

export const FormInfoText = styled(DefaultText)(
    ({ theme: { fontFamily } }) =>
        css`
            font-family: ${fontFamily.secondary};
        `,
);

export const FormLogo = styled.img`
    height: 33px;
    width: 212px;
`;

export const Divider = styled.div<{ marginTB?: number; isEdit?: boolean }>(
    ({ theme: { colors }, marginTB, isEdit }) =>
        css`
            margin: ${marginTB ? marginTB : '0'}px 0;
            background: ${isEdit ? colors.formBlue : colors.placeholder};
            height: 1px;
            width: 100%;
        `,
);

export const BlueDivider = styled.div<{ marginTB?: number }>(
    ({ theme: { colors }, marginTB }) =>
        css`
            margin: ${marginTB ? marginTB : '0'}px 0;
            background: ${colors.formBlue};
            height: 3px;
            width: 100%;
        `,
);

export const DividerLight = styled.div<{ marginTB?: number }>(
    ({ theme: { colors }, marginTB }) =>
        css`
            margin: ${marginTB ? marginTB : '0'}px 0;
            background: ${colors.placeholder}50;
            height: 1px;
            width: 100%;
        `,
);

export const SectionTitle = styled.p(
    ({ theme: { colors, fontSizes, fontFamily, fontWeight } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
        overflow-wrap: break-word;
        white-space: normal;
    `,
);

export const SectionSubtitle = styled.p(
    ({ theme: { colors, fontSizes, fontFamily, fontWeight } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.medium};
        line-height: 30px;
        margin: 0;
        overflow-wrap: break-word;
        white-space: normal;
    `,
);

export const SectionText = styled.p(
    ({ theme: { colors, fontSizes, fontFamily, fontWeight } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.regular};
        line-height: 30px;
        margin: 0;
        overflow-wrap: break-word;
        white-space: normal;
        width: 100%;
    `,
);

export const Input = styled.input(
    ({ theme: { colors, borderRadius, fontFamily, fontSizes, fontWeight } }) =>
        css`
            border-radius: ${borderRadius.textarea}px;
            border: 1px solid ${colors.placeholder};
            color: ${colors.text};
            font-family: ${fontFamily.secondary};
            font-size: ${fontSizes.f14};
            font-weight: ${fontWeight.regular};
            padding: 12px;
            width: 100%;
        `,
);

export const InputWrapper = styled.div`
    display: flex;
    gap: 24px;
`;

export const SymptomsWrapper = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100%;
`;

export const SymptomsInnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
`;

export const InsideWrapper = styled.div`
    display: flex;
    gap: 24px;
`;

export const List = styled.ul`
    list-style: inside;
    padding-inline-start: 10px;
`;

export const ListItem = styled.li(
    ({ theme: { fontFamily, fontSizes, fontWeight, colors } }) => css`
        color: ${colors.text};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        font-weight: ${fontWeight.regular};
        line-height: 30px;
    `,
);

export const StyledInput = styled.textarea<{ displayInlineFlex?: boolean }>(
    ({
        displayInlineFlex,
        theme: { colors, fontFamily, fontSizes, borderRadius },
    }) => css`
        background: transparent;
        border-radius: ${borderRadius.default}px;
        border: 1px solid ${colors.formBlue};
        color: ${colors.text};
        display: ${displayInlineFlex ? 'inline-flex' : 'flex'};
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f14};
        height: auto;
        line-height: 30px;
        margin: 4px;
        min-height: max-content;
        overflow-wrap: break-word;
        overflow-y: hidden;
        padding: 0 6px;
        resize: none;
        white-space: normal;
        width: 100%;
    `,
);

export const StyledButton = styled.button(
    ({ theme: { colors, fontFamily, fontSizes, borderRadius } }) => css`
        align-items: center;
        align-self: flex-end;
        background: ${colors.formBlue};
        border-radius: ${borderRadius.button}px;
        border: none;
        color: ${colors.white};
        cursor: pointer;
        display: flex;
        font-family: ${fontFamily.secondary};
        font-size: ${fontSizes.f16};
        height: 45px;
        justify-content: center;
        line-height: 22px;
        margin-bottom: 20px;
        text-transform: uppercase;
        width: 180px;
    `,
);

export const CheckboxWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 12px 0;
`;
