import React from 'react';
import { SectionSubtitle, StyledInput } from '../../Form.styled';
import { RadioButton } from '../RadioButton/RadioButton';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import {
    RemoveButtonWrapper,
    SymptomsInnerWrapper,
    SymptomsWrapper,
    BlueBackground,
} from '../Styles';

const options = [
    { label: 'słabe', value: 1 },
    { label: 'znaczne', value: 2 },
    { label: 'silne', value: 3 },
];

interface NewGeneralAilmentProps {
    index: number;
    ailment: {
        name: string;
        description: string;
        intensity: number;
        duration: string;
    };
    onChange: (index: number, field: string, value: any) => void;
    onRemove: (index: number) => void;
    isEdit?: boolean;
}

const NewGeneralAilment: React.FC<NewGeneralAilmentProps> = ({
    index,
    ailment,
    onChange,
    onRemove,
    isEdit,
}) => {
    const handleInputChange =
        (field: string) => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            onChange(index, field, event.target.value);
        };

    const handleRadioButtonChange = (value: number) => {
        onChange(index, 'intensity', value);
    };

    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Dodaj dolegliwość</SectionSubtitle>
                    <StyledInput
                        onChange={handleInputChange('name')}
                        placeholder="Dodaj nazwę"
                        defaultValue={ailment.name}
                    />
                </SymptomsInnerWrapper>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Dodaj opis</SectionSubtitle>
                    <StyledInput
                        onChange={handleInputChange('description')}
                        placeholder="Dodaj opis"
                        defaultValue={ailment.description}
                    />
                </SymptomsInnerWrapper>
            </BlueBackground>
            <SymptomsInnerWrapper>
                <SectionSubtitle>Nasilenie: </SectionSubtitle>
                <RadioButton
                    disabled={!isEdit}
                    name={`intensity-${index}`}
                    onChange={handleRadioButtonChange}
                    options={options}
                    selectedValue={ailment.intensity}
                />
            </SymptomsInnerWrapper>
            <SymptomsInnerWrapper>
                <SectionSubtitle>Czas Trwania: </SectionSubtitle>
                <StyledInput
                    onChange={handleInputChange('duration')}
                    placeholder="Dodaj czas trwania"
                    defaultValue={ailment.duration}
                />
            </SymptomsInnerWrapper>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewGeneralAilment;
