import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import {
    FieldPolicy,
    FieldReadFunction,
    TypePolicies,
    TypePolicy,
} from '@apollo/client/cache';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
    [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
    [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    DateTime: any;
    Upload: any;
};

export type Allergy = {
    __typename?: 'Allergy';
    description?: Maybe<Scalars['String']>;
    medications?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type AllergyInput = {
    description?: InputMaybe<Scalars['String']>;
    medications?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

/** Audio represents an audio recording stored in the cloud. It has basic metadata about the file. */
export type Audio = {
    __typename?: 'Audio';
    /** Date and time when the audio recording was created */
    createdAt: Scalars['DateTime'];
    /** Unique identifier of the audio recording */
    id: Scalars['Int'];
    /** MIME type of the audio file */
    mimeType: Scalars['String'];
    /** Name of the audio recording */
    name: Scalars['String'];
    /** Size of the audio file */
    size: Scalars['Int'];
    /** Date and time when the audio recording was last updated */
    updatedAt: Scalars['DateTime'];
    url?: Maybe<Scalars['String']>;
};

/** Author represents the person whe is saying specific transcription text. It can be a doctor or a patient. */
export type Author = 'DOCTOR' | 'NOT_SET' | 'PATIENT';

/** Input type for saving a single transcript chunk */
export type CreateTranscriptChunkInput = {
    /** The author of the transcript chunk. */
    author: ValidAuthor;
    /** The end time of the transcript chunk in seconds. */
    end: Scalars['Float'];
    /** The start time of the transcript chunk. */
    lowQuality?: Scalars['Boolean'];
    /** The start time of the transcript chunk in seconds. */
    start: Scalars['Float'];
    /** The text of the transcript chunk. */
    text: Scalars['String'];
};

/** Input type for deleting a single transcript chunk */
export type DeleteTranscriptChunkInput = {
    /** The id of the transcript chunk to delete */
    id: Scalars['Int'];
};

export type Drug = {
    __typename?: 'Drug';
    description?: Maybe<Scalars['String']>;
    frequency?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
};

export type DrugInput = {
    description?: InputMaybe<Scalars['String']>;
    frequency?: InputMaybe<Scalars['String']>;
    name?: InputMaybe<Scalars['String']>;
};

export type FamilyHistory = {
    __typename?: 'FamilyHistory';
    condition?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    relationship?: Maybe<FamilyRelationship>;
};

export type FamilyHistoryInput = {
    condition?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    relationship?: InputMaybe<FamilyRelationship>;
};

export type FamilyRelationship =
    | 'FIRST_DEGREE'
    | 'NOT_APPLICABLE'
    | 'SECOND_DEGREE'
    | 'THIRD_DEGREE';

/** FileTypeFilters represents the file types that can be used to filter recordings. */
export type FileTypeFilters = 'm4a' | 'mp3' | 'wav';

export type Filters = {
    fileType?: InputMaybe<Array<FileTypeFilters>>;
    search?: InputMaybe<Scalars['String']>;
    transcriptVerificationStatus?: InputMaybe<Array<TranscriptStatusFilters>>;
    verificationStatus?: InputMaybe<Array<VerificationStatus>>;
};

export type GeneralAilment = {
    __typename?: 'GeneralAilment';
    description?: Maybe<Scalars['String']>;
    duration?: Maybe<Scalars['String']>;
    intensity?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
};

export type GeneralAilmentInput = {
    description?: InputMaybe<Scalars['String']>;
    duration?: InputMaybe<Scalars['String']>;
    intensity?: InputMaybe<Scalars['Int']>;
    name?: InputMaybe<Scalars['String']>;
};

export type HealthServices = {
    eprescriptions?: InputMaybe<Scalars['Boolean']>;
    ereferrals?: InputMaybe<Scalars['Boolean']>;
    ezla?: InputMaybe<Scalars['Boolean']>;
};

/** ListRecordingsResponse represents the response of the listRecordings query. */
export type ListRecordingsResponse = {
    __typename?: 'ListRecordingsResponse';
    /** The list of items. */
    data: Array<Recording>;
    /** The number of skipped items */
    skip: Scalars['Int'];
    /** The number of items in response */
    take: Scalars['Int'];
    /** The total number of items */
    total: Scalars['Int'];
};

/** ListTranscriptsResponse represents the response of the listTranscripts query. */
export type ListTranscriptsResponse = {
    __typename?: 'ListTranscriptsResponse';
    /** The list of items. */
    data: Array<Transcript>;
    /** The number of skipped items */
    skip: Scalars['Int'];
    /** The number of items in response */
    take: Scalars['Int'];
    /** The total number of items */
    total: Scalars['Int'];
};

export type Mutation = {
    __typename?: 'Mutation';
    /** Add recording templates **Auth:** Requires `X-Session-Token` header with a valid session token */
    addTemplates: Scalars['String'];
    /** Create a new recording **Auth:** Requires `Api-token` header with a valid token of type `RECORDING` or a valid session token in `X-Session-Token` header */
    createRecording: Scalars['String'];
    /** Create a new recording session. It is valid for 24 hours. **Auth:** Requires `X-Session-Token` header with a valid session token */
    createSession: RecordingSession;
    /**
     * Login with Google. It automatically creates account for non-existing users with data from Google profiles. **Auth:** Requires `x-google-code` header with a valid Google OAuth2 code and `x-redirect-url` header with a redirect uri used for obtaining Google OAuth2 code. During Google OAuth token request,
     *         be sure to have `email` and `profile` scopes included.
     */
    loginWithGoogle: Scalars['String'];
    /** Logout user. **Auth:** Requires `X-Session-Token` header with a valid session token */
    logout: Scalars['Boolean'];
    /** Mark a recording transcript as verified. **Auth:** Requires `X-Session-Token` header with a valid session token */
    markTranscriptVerified: Recording;
    /** Request a report for a recording. **Auth:** Requires `X-Session-Token` header with a valid session token */
    requestReport: Scalars['String'];
    /** Request a transcript for a recording. **Auth:** Requires `X-Session-Token` header with a valid session token */
    requestTranscript: Scalars['String'];
    /** Save a transcript for a recording. Returns created and updated chunks. **Auth:** Requires `X-Session-Token` header with a valid session token */
    saveTranscript: Array<Transcript>;
    /** Update a report. **Auth:** Requires `X-Session-Token` header with a valid session token */
    updateReport: Report;
    /** Update a transcript chunk **Auth:** Requires `X-Session-Token` header with a valid session token */
    updateTranscript: Transcript;
    /** Update current user profile. **Auth:** Requires `X-Session-Token` header with a valid session token */
    updateUser: User;
    /** Verify a recording. **Auth:** Requires `X-Session-Token` header with a valid session token */
    verifyRecording: Recording;
};

export type MutationAddTemplatesArgs = {
    items: Array<Scalars['String']>;
};

export type MutationCreateRecordingArgs = {
    file: Scalars['Upload'];
    session?: InputMaybe<RecordingSessionConfigInput>;
};

export type MutationMarkTranscriptVerifiedArgs = {
    recordingId: Scalars['Int'];
    verified: Scalars['Boolean'];
};

export type MutationRequestReportArgs = {
    recordingId: Scalars['Int'];
};

export type MutationRequestTranscriptArgs = {
    recordingId: Scalars['Int'];
};

export type MutationSaveTranscriptArgs = {
    input: SaveTranscriptInput;
};

export type MutationUpdateReportArgs = {
    data: ReportInput;
    recordingId: Scalars['Float'];
};

export type MutationUpdateTranscriptArgs = {
    input: SaveTranscriptChunkInput;
};

export type MutationUpdateUserArgs = {
    data: UpdateUserInput;
};

export type MutationVerifyRecordingArgs = {
    comment?: InputMaybe<Scalars['String']>;
    recordingId: Scalars['Int'];
    status: VerificationStatus;
};

export type PatientInfo = {
    __typename?: 'PatientInfo';
    name?: Maybe<Scalars['String']>;
    pesel?: Maybe<Scalars['String']>;
};

export type PatientInfoInput = {
    name?: InputMaybe<Scalars['String']>;
    pesel?: InputMaybe<Scalars['String']>;
};

export type PreviousCondition = {
    __typename?: 'PreviousCondition';
    condition?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    medications?: Maybe<Scalars['String']>;
};

export type PreviousConditionInput = {
    condition?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    medications?: InputMaybe<Scalars['String']>;
};

export type Query = {
    __typename?: 'Query';
    /** Get a URL for a new transcript chunk audio. **Auth:** Requires `X-Session-Token` header with a valid session token */
    getNewTranscriptUrl: Scalars['String'];
    /** Get a recording by ID. **Auth:** Requires `X-Session-Token` header with a valid session token */
    getRecording?: Maybe<Recording>;
    /** Get a report. **Auth:** Requires `X-Session-Token` header with a valid session token */
    getReport: Report;
    /** Get a URL for a report. Link valid for 1 hour **Auth:** Requires `X-Session-Token` header with a valid session token */
    getReportUrl: Scalars['String'];
    /** Get a list of recording sessions **Auth:** Requires `X-Session-Token` header with a valid session token */
    getSessions: Array<RecordingSession>;
    /** Get a URL for a transcript audio. **Auth:** Requires `X-Session-Token` header with a valid session token */
    getTranscriptUrl: Scalars['String'];
    /** Get a list of recordings. **Auth:** Requires `X-Session-Token` header with a valid session token */
    listRecordings: ListRecordingsResponse;
    /** Get a list of transcripts for a recording. **Auth:** Requires `X-Session-Token` header with a valid session token */
    listTranscripts: ListTranscriptsResponse;
    /** Get current user profile. **Auth:** Requires `X-Session-Token` header with a valid session token */
    me: User;
};

export type QueryGetNewTranscriptUrlArgs = {
    end: Scalars['Float'];
    recordingId: Scalars['Int'];
    start: Scalars['Float'];
};

export type QueryGetRecordingArgs = {
    id: Scalars['Int'];
};

export type QueryGetReportArgs = {
    recordingId: Scalars['Float'];
};

export type QueryGetReportUrlArgs = {
    recordingId: Scalars['Int'];
};

export type QueryGetTranscriptUrlArgs = {
    id: Scalars['Int'];
};

export type QueryListRecordingsArgs = {
    filters?: InputMaybe<Filters>;
    skip?: Scalars['Int'];
    sortBy?: InputMaybe<RecordingSortableFields>;
    sortOrder?: SortOrder;
    take?: Scalars['Int'];
};

export type QueryListTranscriptsArgs = {
    recordingId: Scalars['Int'];
    skip?: Scalars['Int'];
    sortBy?: InputMaybe<TranscriptSortableFields>;
    sortOrder?: SortOrder;
    take?: Scalars['Int'];
};

export type Recommendation = {
    __typename?: 'Recommendation';
    items?: Maybe<Array<Scalars['String']>>;
    medications?: Maybe<Array<Scalars['String']>>;
};

export type RecommendationInput = {
    items?: InputMaybe<Array<Scalars['String']>>;
    medications?: InputMaybe<Array<Scalars['String']>>;
};

export type Recording = {
    __typename?: 'Recording';
    /** The audio of the recording. */
    audio: Audio;
    /** The date and time when the recording was created. */
    createdAt: Scalars['DateTime'];
    hasReport: Scalars['Boolean'];
    id: Scalars['Int'];
    isReportQueued: Scalars['Boolean'];
    /** Determines if whole transcript was verified by a user. */
    isWholeTranscriptVerified: Scalars['Boolean'];
    name: Scalars['String'];
    status: VerificationStatus;
    /** The status of the transcript. */
    transcriptStatus: TranscriptLoadStatus;
    transcriptVerificationStatus: TranscriptVerificationStatus;
    /** The users who participated in the verification of the recording transcript. */
    transcriptVerifiedBy: Array<User>;
    /** The date and time when the recording was last updated. */
    updatedAt: Scalars['DateTime'];
    /** The verifications of the recording. */
    verifications?: Maybe<Array<Verification>>;
    /** The user who marked the recording transcript as verified. */
    wholeTranscriptVerifiedBy?: Maybe<User>;
};

export type RecordingSession = {
    __typename?: 'RecordingSession';
    /** Text to be read in the recording session */
    text: Scalars['String'];
    /** Unique identifier of the recording session */
    uuid: Scalars['String'];
};

export type RecordingSessionConfigInput = {
    /** Recording length in seconds */
    length: Scalars['Float'];
    /** Unique identifier of the recording session */
    uuid: Scalars['String'];
};

/** RecordingSortableFields represents the fields that can be used to sort recordings. */
export type RecordingSortableFields = 'createdAt' | 'id' | 'updatedAt';

/** Report represents a report for a recording. */
export type Report = {
    __typename?: 'Report';
    /** Allergy information */
    allergies?: Maybe<Allergy>;
    /** Community interview information */
    communityInterview?: Maybe<Array<SingleValueField>>;
    /** Diagnosis information */
    diagnosis?: Maybe<Array<SingleValueField>>;
    /** Drug information */
    drugs?: Maybe<Array<Drug>>;
    /** Family history information */
    familyHistory?: Maybe<Array<FamilyHistory>>;
    /** General ailment information */
    generalAilments?: Maybe<Array<GeneralAilment>>;
    /** Patient information */
    patientInfo?: Maybe<PatientInfo>;
    /** Previous conditions information */
    previousConditions?: Maybe<PreviousCondition>;
    /** Recommendations information */
    recommendations?: Maybe<Array<Recommendation>>;
    /** Health services information */
    services?: Maybe<Services>;
    /** Surgery hospitalization information */
    surgeryHospitalizations?: Maybe<Array<SurgeryHospitalization>>;
};

export type ReportInput = {
    /** Allergy information */
    allergies?: InputMaybe<AllergyInput>;
    /** Community interview information */
    communityInterview?: InputMaybe<Array<SingleValueFieldInput>>;
    /** Diagnosis information */
    diagnosis?: InputMaybe<Array<SingleValueFieldInput>>;
    /** Drug information */
    drugs?: InputMaybe<Array<DrugInput>>;
    /** Family history information */
    familyHistory?: InputMaybe<Array<FamilyHistoryInput>>;
    /** General ailment information */
    generalAilments?: InputMaybe<Array<GeneralAilmentInput>>;
    /** Patient information */
    patientInfo?: InputMaybe<PatientInfoInput>;
    /** Previous conditions information */
    previousConditions?: InputMaybe<PreviousConditionInput>;
    /** Recommendations information */
    recommendations?: InputMaybe<Array<RecommendationInput>>;
    /** Health services information */
    services?: InputMaybe<HealthServices>;
    /** Surgery hospitalization information */
    surgeryHospitalizations?: InputMaybe<Array<SurgeryHospitalizationInput>>;
};

/** Input type for saving a single transcript chunk */
export type SaveTranscriptChunkInput = {
    /** The author of the transcript chunk. */
    author?: InputMaybe<ValidAuthor>;
    /** The end time of the transcript chunk in seconds. */
    end?: InputMaybe<Scalars['Float']>;
    /** The start time of the transcript chunk. */
    lowQuality?: InputMaybe<Scalars['Boolean']>;
    /** The start time of the transcript chunk in seconds. */
    start?: InputMaybe<Scalars['Float']>;
    /** The text of the transcript chunk. */
    text?: InputMaybe<Scalars['String']>;
    /** The id of the recording to which the transcript chunk belongs. */
    transcriptId: Scalars['Int'];
};

/** Input type for saving a transcript */
export type SaveTranscriptInput = {
    /** The transcript chunks to create */
    create?: InputMaybe<Array<CreateTranscriptChunkInput>>;
    /** The transcript chunks to delete */
    delete?: InputMaybe<Array<DeleteTranscriptChunkInput>>;
    /** The id of the recording to which the transcript belongs. */
    recordingId: Scalars['Int'];
    /** The transcript chunks to update */
    update?: InputMaybe<Array<SaveTranscriptChunkInput>>;
};

export type Services = {
    __typename?: 'Services';
    eprescriptions?: Maybe<Scalars['Boolean']>;
    ereferrals?: Maybe<Scalars['Boolean']>;
    ezla?: Maybe<Scalars['Boolean']>;
};

export type SingleValueField = {
    __typename?: 'SingleValueField';
    details?: Maybe<Scalars['String']>;
};

export type SingleValueFieldInput = {
    details?: InputMaybe<Scalars['String']>;
};

export type SortOrder = 'asc' | 'desc';

export type SurgeryHospitalization = {
    __typename?: 'SurgeryHospitalization';
    cause?: Maybe<Scalars['String']>;
    description?: Maybe<Scalars['String']>;
    duration?: Maybe<Scalars['String']>;
};

export type SurgeryHospitalizationInput = {
    cause?: InputMaybe<Scalars['String']>;
    description?: InputMaybe<Scalars['String']>;
    duration?: InputMaybe<Scalars['String']>;
};

export type Transcript = {
    __typename?: 'Transcript';
    /** The author of the transcript chunk */
    author: Author;
    /** The date and time when the transcript chunk was created. */
    createdAt: Scalars['DateTime'];
    /** The end time of the transcript chunk in seconds */
    end: Scalars['Float'];
    id: Scalars['Int'];
    /** Whether the audio of the transcript chunk is low quality */
    lowQuality: Scalars['Boolean'];
    /** The revisions of the transcript chunk */
    revisions: Array<TranscriptRevision>;
    /** The start time of the transcript chunk in seconds */
    start: Scalars['Float'];
    /** The text of the transcript chunk */
    text: Scalars['String'];
    /** The date and time when the transcript chunk was last updated. */
    updatedAt: Scalars['DateTime'];
};

/** TranscriptLoadStatus represents the status of the transcript before verifications. */
export type TranscriptLoadStatus =
    | 'LOADED'
    | 'NOT_REQUESTED'
    | 'PENDING'
    | 'QUEUED';

/** TranscriptRevision represents a revision of a transcript chunk. */
export type TranscriptRevision = {
    __typename?: 'TranscriptRevision';
    /** The author of the transcript chunk */
    author?: Maybe<Author>;
    /** The date and time when the transcript revision was created. */
    createdAt: Scalars['DateTime'];
    /** The end time of the transcript chunk in seconds */
    end?: Maybe<Scalars['Float']>;
    id: Scalars['Int'];
    /** Whether the audio of the transcript is low quality */
    lowQuality?: Maybe<Scalars['Boolean']>;
    /** The start time of the transcript chunk in seconds */
    start?: Maybe<Scalars['Float']>;
    /** The text of the transcript revision */
    text?: Maybe<Scalars['String']>;
    /** The date and time when the transcript revision was last updated. */
    updatedAt: Scalars['DateTime'];
    /** The author of the transcript revision */
    user: User;
};

/** TranscriptSortableFields represents the fields that can be used to sort transcripts. */
export type TranscriptSortableFields =
    | 'createdAt'
    | 'end'
    | 'id'
    | 'start'
    | 'updatedAt';

/** TranscriptStatusFilters represents the status of the transcript. */
export type TranscriptStatusFilters =
    | 'IN_PROGRESS'
    | 'NOT_GENERATED'
    | 'NOT_VERIFIED'
    | 'VERIFIED';

/** TranscriptVerificationStatus represents the status of the transcript after verifications. */
export type TranscriptVerificationStatus =
    | 'IN_PROGRESS'
    | 'NOT_VERIFIED'
    | 'VERIFIED';

/** Update user input */
export type UpdateUserInput = {
    /** User avatar. Must be image. */
    avatar?: InputMaybe<Scalars['Upload']>;
    /** User name */
    name?: InputMaybe<Scalars['String']>;
};

/** User object */
export type User = {
    __typename?: 'User';
    avatar?: Maybe<Scalars['String']>;
    /** User email */
    email: Scalars['String'];
    id: Scalars['Int'];
    /** User name */
    name: Scalars['String'];
    /** User role */
    role: UserRole;
};

export type UserRole = 'QA' | 'QA_SENIOR';

/** ValidAuthor represents the valid authors that can be used to save a transcript. */
export type ValidAuthor = 'DOCTOR' | 'PATIENT';

/** Verification represents a verification of a recording. */
export type Verification = {
    __typename?: 'Verification';
    /** Comment for rejected verifications */
    comment?: Maybe<Scalars['String']>;
    /** The date and time when the verification was created. */
    createdAt: Scalars['DateTime'];
    id: Scalars['Int'];
    /** The status of the verification */
    status: VerificationStatus;
    /** The date and time when the verification was last updated. */
    updatedAt: Scalars['DateTime'];
    /** The author of the verification */
    user: User;
    /** The ID of the verification's author */
    userId: Scalars['Int'];
};

/** VerificationStatus represents the verification status of the recording. */
export type VerificationStatus = 'ACCEPTED' | 'PENDING' | 'REJECTED';

export type AudioFragment = {
    __typename?: 'Audio';
    createdAt: any;
    id: number;
    mimeType: string;
    name: string;
    size: number;
    updatedAt: any;
    url?: string | null;
};

export type TranscriptFragment = {
    __typename?: 'Transcript';
    author: Author;
    createdAt: any;
    end: number;
    id: number;
    start: number;
    text: string;
    updatedAt: any;
    revisions: Array<{
        __typename?: 'TranscriptRevision';
        createdAt: any;
        end?: number | null;
        id: number;
        start?: number | null;
        text?: string | null;
        updatedAt: any;
        user: {
            __typename?: 'User';
            avatar?: string | null;
            email: string;
            id: number;
            name: string;
            role: UserRole;
        };
    }>;
};

export type TranscriptRevisionFragment = {
    __typename?: 'TranscriptRevision';
    createdAt: any;
    end?: number | null;
    id: number;
    start?: number | null;
    text?: string | null;
    updatedAt: any;
    user: {
        __typename?: 'User';
        avatar?: string | null;
        email: string;
        id: number;
        name: string;
        role: UserRole;
    };
};

export type UserFragment = {
    __typename?: 'User';
    avatar?: string | null;
    email: string;
    id: number;
    name: string;
    role: UserRole;
};

export type VerificationFragment = {
    __typename?: 'Verification';
    comment?: string | null;
    createdAt: any;
    id: number;
    status: VerificationStatus;
    updatedAt: any;
    userId: number;
    user: {
        __typename?: 'User';
        avatar?: string | null;
        email: string;
        id: number;
        name: string;
        role: UserRole;
    };
};

export type CreateRecordingMutationVariables = Exact<{
    file: Scalars['Upload'];
    session?: InputMaybe<RecordingSessionConfigInput>;
}>;

export type CreateRecordingMutation = {
    __typename?: 'Mutation';
    createRecording: string;
};

export type CreateSessionMutationVariables = Exact<{ [key: string]: never }>;

export type CreateSessionMutation = {
    __typename?: 'Mutation';
    createSession: {
        __typename?: 'RecordingSession';
        text: string;
        uuid: string;
    };
};

export type LoginWithGoogleMutationVariables = Exact<{ [key: string]: never }>;

export type LoginWithGoogleMutation = {
    __typename?: 'Mutation';
    loginWithGoogle: string;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type MarkTranscriptVerifiedMutationVariables = Exact<{
    recordingId: Scalars['Int'];
    verified: Scalars['Boolean'];
}>;

export type MarkTranscriptVerifiedMutation = {
    __typename?: 'Mutation';
    markTranscriptVerified: {
        __typename?: 'Recording';
        isReportQueued: boolean;
        createdAt: any;
        id: number;
        status: VerificationStatus;
        transcriptStatus: TranscriptLoadStatus;
        transcriptVerificationStatus: TranscriptVerificationStatus;
        updatedAt: any;
        hasReport: boolean;
        audio: {
            __typename?: 'Audio';
            createdAt: any;
            id: number;
            mimeType: string;
            name: string;
            size: number;
            updatedAt: any;
            url?: string | null;
        };
        verifications?: Array<{
            __typename?: 'Verification';
            comment?: string | null;
            createdAt: any;
            id: number;
            status: VerificationStatus;
            updatedAt: any;
            userId: number;
            user: {
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            };
        }> | null;
    };
};

export type RequestReportMutationVariables = Exact<{
    recordingId: Scalars['Int'];
}>;

export type RequestReportMutation = {
    __typename?: 'Mutation';
    requestReport: string;
};

export type RequestTranscriptMutationVariables = Exact<{
    recordingId: Scalars['Int'];
}>;

export type RequestTranscriptMutation = {
    __typename?: 'Mutation';
    requestTranscript: string;
};

export type SaveTranscriptMutationVariables = Exact<{
    input: SaveTranscriptInput;
}>;

export type SaveTranscriptMutation = {
    __typename?: 'Mutation';
    saveTranscript: Array<{
        __typename?: 'Transcript';
        author: Author;
        createdAt: any;
        end: number;
        id: number;
        start: number;
        text: string;
        updatedAt: any;
        revisions: Array<{
            __typename?: 'TranscriptRevision';
            createdAt: any;
            end?: number | null;
            id: number;
            start?: number | null;
            text?: string | null;
            updatedAt: any;
            user: {
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            };
        }>;
    }>;
};

export type UpdateReportMutationVariables = Exact<{
    data: ReportInput;
    recordingId: Scalars['Float'];
}>;

export type UpdateReportMutation = {
    __typename?: 'Mutation';
    updateReport: {
        __typename?: 'Report';
        allergies?: {
            __typename?: 'Allergy';
            description?: string | null;
            medications?: string | null;
            name?: string | null;
        } | null;
        communityInterview?: Array<{
            __typename?: 'SingleValueField';
            details?: string | null;
        }> | null;
        diagnosis?: Array<{
            __typename?: 'SingleValueField';
            details?: string | null;
        }> | null;
        drugs?: Array<{
            __typename?: 'Drug';
            description?: string | null;
            frequency?: string | null;
            name?: string | null;
        }> | null;
        familyHistory?: Array<{
            __typename?: 'FamilyHistory';
            condition?: string | null;
            description?: string | null;
            relationship?: FamilyRelationship | null;
        }> | null;
        generalAilments?: Array<{
            __typename?: 'GeneralAilment';
            description?: string | null;
            duration?: string | null;
            intensity?: number | null;
            name?: string | null;
        }> | null;
        patientInfo?: {
            __typename?: 'PatientInfo';
            name?: string | null;
            pesel?: string | null;
        } | null;
        previousConditions?: {
            __typename?: 'PreviousCondition';
            condition?: string | null;
            description?: string | null;
            medications?: string | null;
        } | null;
        recommendations?: Array<{
            __typename?: 'Recommendation';
            items?: Array<string> | null;
            medications?: Array<string> | null;
        }> | null;
        services?: {
            __typename?: 'Services';
            eprescriptions?: boolean | null;
            ereferrals?: boolean | null;
            ezla?: boolean | null;
        } | null;
        surgeryHospitalizations?: Array<{
            __typename?: 'SurgeryHospitalization';
            cause?: string | null;
            description?: string | null;
            duration?: string | null;
        }> | null;
    };
};

export type UpdateTranscriptMutationVariables = Exact<{
    input: SaveTranscriptChunkInput;
}>;

export type UpdateTranscriptMutation = {
    __typename?: 'Mutation';
    updateTranscript: {
        __typename?: 'Transcript';
        author: Author;
        createdAt: any;
        end: number;
        id: number;
        lowQuality: boolean;
        start: number;
        text: string;
        updatedAt: any;
        revisions: Array<{
            __typename?: 'TranscriptRevision';
            createdAt: any;
            end?: number | null;
            id: number;
            start?: number | null;
            text?: string | null;
            updatedAt: any;
            user: {
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            };
        }>;
    };
};

export type UpdateUserMutationVariables = Exact<{
    input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
    __typename?: 'Mutation';
    updateUser: {
        __typename?: 'User';
        avatar?: string | null;
        email: string;
        id: number;
        name: string;
        role: UserRole;
    };
};

export type VerifyRecordingMutationVariables = Exact<{
    comment?: InputMaybe<Scalars['String']>;
    recordingId: Scalars['Int'];
    status: VerificationStatus;
}>;

export type VerifyRecordingMutation = {
    __typename?: 'Mutation';
    verifyRecording: {
        __typename?: 'Recording';
        createdAt: any;
        id: number;
        status: VerificationStatus;
        transcriptVerificationStatus: TranscriptVerificationStatus;
        updatedAt: any;
        audio: {
            __typename?: 'Audio';
            createdAt: any;
            id: number;
            mimeType: string;
            name: string;
            size: number;
            updatedAt: any;
            url?: string | null;
        };
        verifications?: Array<{
            __typename?: 'Verification';
            comment?: string | null;
            createdAt: any;
            id: number;
            status: VerificationStatus;
            updatedAt: any;
            userId: number;
            user: {
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            };
        }> | null;
    };
};

export type AuthMeQueryVariables = Exact<{ [key: string]: never }>;

export type AuthMeQuery = {
    __typename?: 'Query';
    me: {
        __typename?: 'User';
        avatar?: string | null;
        email: string;
        id: number;
        name: string;
        role: UserRole;
    };
};

export type GetNewTranscriptUrlQueryVariables = Exact<{
    end: Scalars['Float'];
    recordingId: Scalars['Int'];
    start: Scalars['Float'];
}>;

export type GetNewTranscriptUrlQuery = {
    __typename?: 'Query';
    getNewTranscriptUrl: string;
};

export type GetRecordingQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetRecordingQuery = {
    __typename?: 'Query';
    getRecording?: {
        __typename?: 'Recording';
        isWholeTranscriptVerified: boolean;
        name: string;
        isReportQueued: boolean;
        createdAt: any;
        id: number;
        status: VerificationStatus;
        transcriptStatus: TranscriptLoadStatus;
        transcriptVerificationStatus: TranscriptVerificationStatus;
        updatedAt: any;
        hasReport: boolean;
        audio: {
            __typename?: 'Audio';
            createdAt: any;
            id: number;
            mimeType: string;
            name: string;
            size: number;
            updatedAt: any;
            url?: string | null;
        };
        verifications?: Array<{
            __typename?: 'Verification';
            comment?: string | null;
            createdAt: any;
            id: number;
            status: VerificationStatus;
            updatedAt: any;
            userId: number;
            user: {
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            };
        }> | null;
        transcriptVerifiedBy: Array<{
            __typename?: 'User';
            avatar?: string | null;
            email: string;
            id: number;
            name: string;
            role: UserRole;
        }>;
        wholeTranscriptVerifiedBy?: {
            __typename?: 'User';
            avatar?: string | null;
            email: string;
            id: number;
            name: string;
            role: UserRole;
        } | null;
    } | null;
};

export type GetReportQueryVariables = Exact<{
    recordingId: Scalars['Float'];
}>;

export type GetReportQuery = {
    __typename?: 'Query';
    getReport: {
        __typename?: 'Report';
        allergies?: {
            __typename?: 'Allergy';
            description?: string | null;
            medications?: string | null;
            name?: string | null;
        } | null;
        communityInterview?: Array<{
            __typename?: 'SingleValueField';
            details?: string | null;
        }> | null;
        diagnosis?: Array<{
            __typename?: 'SingleValueField';
            details?: string | null;
        }> | null;
        drugs?: Array<{
            __typename?: 'Drug';
            description?: string | null;
            frequency?: string | null;
            name?: string | null;
        }> | null;
        familyHistory?: Array<{
            __typename?: 'FamilyHistory';
            condition?: string | null;
            description?: string | null;
            relationship?: FamilyRelationship | null;
        }> | null;
        generalAilments?: Array<{
            __typename?: 'GeneralAilment';
            description?: string | null;
            duration?: string | null;
            intensity?: number | null;
            name?: string | null;
        }> | null;
        patientInfo?: {
            __typename?: 'PatientInfo';
            name?: string | null;
            pesel?: string | null;
        } | null;
        previousConditions?: {
            __typename?: 'PreviousCondition';
            condition?: string | null;
            description?: string | null;
            medications?: string | null;
        } | null;
        recommendations?: Array<{
            __typename?: 'Recommendation';
            items?: Array<string> | null;
            medications?: Array<string> | null;
        }> | null;
        services?: {
            __typename?: 'Services';
            eprescriptions?: boolean | null;
            ereferrals?: boolean | null;
            ezla?: boolean | null;
        } | null;
        surgeryHospitalizations?: Array<{
            __typename?: 'SurgeryHospitalization';
            cause?: string | null;
            description?: string | null;
            duration?: string | null;
        }> | null;
    };
};

export type GetReportUrlQueryVariables = Exact<{
    recordingId: Scalars['Int'];
}>;

export type GetReportUrlQuery = { __typename?: 'Query'; getReportUrl: string };

export type GetSessionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSessionsQuery = {
    __typename?: 'Query';
    getSessions: Array<{
        __typename?: 'RecordingSession';
        text: string;
        uuid: string;
    }>;
};

export type GetTranscriptUrlQueryVariables = Exact<{
    id: Scalars['Int'];
}>;

export type GetTranscriptUrlQuery = {
    __typename?: 'Query';
    getTranscriptUrl: string;
};

export type ListRecordingsQueryVariables = Exact<{
    filters?: InputMaybe<Filters>;
    skip: Scalars['Int'];
    sortBy?: InputMaybe<RecordingSortableFields>;
    sortOrder: SortOrder;
    take: Scalars['Int'];
}>;

export type ListRecordingsQuery = {
    __typename?: 'Query';
    listRecordings: {
        __typename?: 'ListRecordingsResponse';
        skip: number;
        take: number;
        total: number;
        data: Array<{
            __typename?: 'Recording';
            isWholeTranscriptVerified: boolean;
            name: string;
            isReportQueued: boolean;
            createdAt: any;
            id: number;
            status: VerificationStatus;
            transcriptStatus: TranscriptLoadStatus;
            transcriptVerificationStatus: TranscriptVerificationStatus;
            updatedAt: any;
            hasReport: boolean;
            audio: {
                __typename?: 'Audio';
                createdAt: any;
                id: number;
                mimeType: string;
                name: string;
                size: number;
                updatedAt: any;
                url?: string | null;
            };
            verifications?: Array<{
                __typename?: 'Verification';
                comment?: string | null;
                createdAt: any;
                id: number;
                status: VerificationStatus;
                updatedAt: any;
                userId: number;
                user: {
                    __typename?: 'User';
                    avatar?: string | null;
                    email: string;
                    id: number;
                    name: string;
                    role: UserRole;
                };
            }> | null;
            transcriptVerifiedBy: Array<{
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            }>;
            wholeTranscriptVerifiedBy?: {
                __typename?: 'User';
                avatar?: string | null;
                email: string;
                id: number;
                name: string;
                role: UserRole;
            } | null;
        }>;
    };
};

export type ListTranscriptsQueryVariables = Exact<{
    recordingId: Scalars['Int'];
    skip: Scalars['Int'];
    sortBy?: InputMaybe<TranscriptSortableFields>;
    sortOrder: SortOrder;
    take: Scalars['Int'];
}>;

export type ListTranscriptsQuery = {
    __typename?: 'Query';
    listTranscripts: {
        __typename?: 'ListTranscriptsResponse';
        skip: number;
        take: number;
        total: number;
        data: Array<{
            __typename?: 'Transcript';
            author: Author;
            createdAt: any;
            end: number;
            id: number;
            start: number;
            text: string;
            updatedAt: any;
            lowQuality: boolean;
            revisions: Array<{
                __typename?: 'TranscriptRevision';
                createdAt: any;
                end?: number | null;
                id: number;
                start?: number | null;
                text?: string | null;
                updatedAt: any;
                user: {
                    __typename?: 'User';
                    avatar?: string | null;
                    email: string;
                    id: number;
                    name: string;
                    role: UserRole;
                };
            }>;
        }>;
    };
};

export const AudioFragmentDoc = gql`
    fragment Audio on Audio {
        createdAt
        id
        mimeType
        name
        size
        updatedAt
        url
    }
`;
export const UserFragmentDoc = gql`
    fragment User on User {
        avatar
        email
        id
        name
        role
    }
`;
export const TranscriptRevisionFragmentDoc = gql`
    fragment TranscriptRevision on TranscriptRevision {
        createdAt
        end
        id
        start
        text
        updatedAt
        user {
            ...User
        }
    }
    ${UserFragmentDoc}
`;
export const TranscriptFragmentDoc = gql`
    fragment Transcript on Transcript {
        author
        createdAt
        end
        id
        revisions {
            ...TranscriptRevision
        }
        start
        text
        updatedAt
    }
    ${TranscriptRevisionFragmentDoc}
`;
export const VerificationFragmentDoc = gql`
    fragment Verification on Verification {
        comment
        createdAt
        id
        status
        updatedAt
        user {
            ...User
        }
        userId
    }
    ${UserFragmentDoc}
`;
export const CreateRecordingDocument = gql`
    mutation CreateRecording(
        $file: Upload!
        $session: RecordingSessionConfigInput
    ) {
        createRecording(file: $file, session: $session)
    }
`;
export type CreateRecordingMutationFn = Apollo.MutationFunction<
    CreateRecordingMutation,
    CreateRecordingMutationVariables
>;

/**
 * __useCreateRecordingMutation__
 *
 * To run a mutation, you first call `useCreateRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordingMutation, { data, loading, error }] = useCreateRecordingMutation({
 *   variables: {
 *      file: // value for 'file'
 *      session: // value for 'session'
 *   },
 * });
 */
export function useCreateRecordingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateRecordingMutation,
        CreateRecordingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateRecordingMutation,
        CreateRecordingMutationVariables
    >(CreateRecordingDocument, options);
}
export type CreateRecordingMutationHookResult = ReturnType<
    typeof useCreateRecordingMutation
>;
export type CreateRecordingMutationResult =
    Apollo.MutationResult<CreateRecordingMutation>;
export type CreateRecordingMutationOptions = Apollo.BaseMutationOptions<
    CreateRecordingMutation,
    CreateRecordingMutationVariables
>;
export const CreateSessionDocument = gql`
    mutation CreateSession {
        createSession {
            text
            uuid
        }
    }
`;
export type CreateSessionMutationFn = Apollo.MutationFunction<
    CreateSessionMutation,
    CreateSessionMutationVariables
>;

/**
 * __useCreateSessionMutation__
 *
 * To run a mutation, you first call `useCreateSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSessionMutation, { data, loading, error }] = useCreateSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateSessionMutation(
    baseOptions?: Apollo.MutationHookOptions<
        CreateSessionMutation,
        CreateSessionMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        CreateSessionMutation,
        CreateSessionMutationVariables
    >(CreateSessionDocument, options);
}
export type CreateSessionMutationHookResult = ReturnType<
    typeof useCreateSessionMutation
>;
export type CreateSessionMutationResult =
    Apollo.MutationResult<CreateSessionMutation>;
export type CreateSessionMutationOptions = Apollo.BaseMutationOptions<
    CreateSessionMutation,
    CreateSessionMutationVariables
>;
export const LoginWithGoogleDocument = gql`
    mutation LoginWithGoogle {
        loginWithGoogle
    }
`;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *   },
 * });
 */
export function useLoginWithGoogleMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LoginWithGoogleMutation,
        LoginWithGoogleMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        LoginWithGoogleMutation,
        LoginWithGoogleMutationVariables
    >(LoginWithGoogleDocument, options);
}
export type LoginWithGoogleMutationHookResult = ReturnType<
    typeof useLoginWithGoogleMutation
>;
export type LoginWithGoogleMutationResult =
    Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
>;
export const LogoutDocument = gql`
    mutation Logout {
        logout
    }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
    LogoutMutation,
    LogoutMutationVariables
>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
    baseOptions?: Apollo.MutationHookOptions<
        LogoutMutation,
        LogoutMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(
        LogoutDocument,
        options,
    );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
    LogoutMutation,
    LogoutMutationVariables
>;
export const MarkTranscriptVerifiedDocument = gql`
    mutation MarkTranscriptVerified($recordingId: Int!, $verified: Boolean!) {
        markTranscriptVerified(recordingId: $recordingId, verified: $verified) {
            audio {
                ...Audio
            }
            verifications {
                comment
                createdAt
                id
                status
                updatedAt
                user {
                    ...User
                }
                userId
            }
            isReportQueued
            createdAt
            id
            status
            transcriptStatus
            transcriptVerificationStatus
            transcriptVerificationStatus
            updatedAt
            hasReport
        }
    }
    ${AudioFragmentDoc}
    ${UserFragmentDoc}
`;
export type MarkTranscriptVerifiedMutationFn = Apollo.MutationFunction<
    MarkTranscriptVerifiedMutation,
    MarkTranscriptVerifiedMutationVariables
>;

/**
 * __useMarkTranscriptVerifiedMutation__
 *
 * To run a mutation, you first call `useMarkTranscriptVerifiedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTranscriptVerifiedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTranscriptVerifiedMutation, { data, loading, error }] = useMarkTranscriptVerifiedMutation({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *      verified: // value for 'verified'
 *   },
 * });
 */
export function useMarkTranscriptVerifiedMutation(
    baseOptions?: Apollo.MutationHookOptions<
        MarkTranscriptVerifiedMutation,
        MarkTranscriptVerifiedMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        MarkTranscriptVerifiedMutation,
        MarkTranscriptVerifiedMutationVariables
    >(MarkTranscriptVerifiedDocument, options);
}
export type MarkTranscriptVerifiedMutationHookResult = ReturnType<
    typeof useMarkTranscriptVerifiedMutation
>;
export type MarkTranscriptVerifiedMutationResult =
    Apollo.MutationResult<MarkTranscriptVerifiedMutation>;
export type MarkTranscriptVerifiedMutationOptions = Apollo.BaseMutationOptions<
    MarkTranscriptVerifiedMutation,
    MarkTranscriptVerifiedMutationVariables
>;
export const RequestReportDocument = gql`
    mutation RequestReport($recordingId: Int!) {
        requestReport(recordingId: $recordingId)
    }
`;
export type RequestReportMutationFn = Apollo.MutationFunction<
    RequestReportMutation,
    RequestReportMutationVariables
>;

/**
 * __useRequestReportMutation__
 *
 * To run a mutation, you first call `useRequestReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestReportMutation, { data, loading, error }] = useRequestReportMutation({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useRequestReportMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestReportMutation,
        RequestReportMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RequestReportMutation,
        RequestReportMutationVariables
    >(RequestReportDocument, options);
}
export type RequestReportMutationHookResult = ReturnType<
    typeof useRequestReportMutation
>;
export type RequestReportMutationResult =
    Apollo.MutationResult<RequestReportMutation>;
export type RequestReportMutationOptions = Apollo.BaseMutationOptions<
    RequestReportMutation,
    RequestReportMutationVariables
>;
export const RequestTranscriptDocument = gql`
    mutation RequestTranscript($recordingId: Int!) {
        requestTranscript(recordingId: $recordingId)
    }
`;
export type RequestTranscriptMutationFn = Apollo.MutationFunction<
    RequestTranscriptMutation,
    RequestTranscriptMutationVariables
>;

/**
 * __useRequestTranscriptMutation__
 *
 * To run a mutation, you first call `useRequestTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestTranscriptMutation, { data, loading, error }] = useRequestTranscriptMutation({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useRequestTranscriptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        RequestTranscriptMutation,
        RequestTranscriptMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        RequestTranscriptMutation,
        RequestTranscriptMutationVariables
    >(RequestTranscriptDocument, options);
}
export type RequestTranscriptMutationHookResult = ReturnType<
    typeof useRequestTranscriptMutation
>;
export type RequestTranscriptMutationResult =
    Apollo.MutationResult<RequestTranscriptMutation>;
export type RequestTranscriptMutationOptions = Apollo.BaseMutationOptions<
    RequestTranscriptMutation,
    RequestTranscriptMutationVariables
>;
export const SaveTranscriptDocument = gql`
    mutation SaveTranscript($input: SaveTranscriptInput!) {
        saveTranscript(input: $input) {
            author
            createdAt
            end
            id
            revisions {
                ...TranscriptRevision
            }
            start
            text
            updatedAt
        }
    }
    ${TranscriptRevisionFragmentDoc}
`;
export type SaveTranscriptMutationFn = Apollo.MutationFunction<
    SaveTranscriptMutation,
    SaveTranscriptMutationVariables
>;

/**
 * __useSaveTranscriptMutation__
 *
 * To run a mutation, you first call `useSaveTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTranscriptMutation, { data, loading, error }] = useSaveTranscriptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveTranscriptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        SaveTranscriptMutation,
        SaveTranscriptMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        SaveTranscriptMutation,
        SaveTranscriptMutationVariables
    >(SaveTranscriptDocument, options);
}
export type SaveTranscriptMutationHookResult = ReturnType<
    typeof useSaveTranscriptMutation
>;
export type SaveTranscriptMutationResult =
    Apollo.MutationResult<SaveTranscriptMutation>;
export type SaveTranscriptMutationOptions = Apollo.BaseMutationOptions<
    SaveTranscriptMutation,
    SaveTranscriptMutationVariables
>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($data: ReportInput!, $recordingId: Float!) {
        updateReport(data: $data, recordingId: $recordingId) {
            allergies {
                description
                medications
                name
            }
            communityInterview {
                details
            }
            diagnosis {
                details
            }
            drugs {
                description
                frequency
                name
            }
            familyHistory {
                condition
                description
                relationship
            }
            generalAilments {
                description
                duration
                intensity
                name
            }
            patientInfo {
                name
                pesel
            }
            previousConditions {
                condition
                description
                medications
            }
            recommendations {
                items
                medications
            }
            services {
                eprescriptions
                ereferrals
                ezla
            }
            surgeryHospitalizations {
                cause
                description
                duration
            }
        }
    }
`;
export type UpdateReportMutationFn = Apollo.MutationFunction<
    UpdateReportMutation,
    UpdateReportMutationVariables
>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      data: // value for 'data'
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useUpdateReportMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateReportMutation,
        UpdateReportMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateReportMutation,
        UpdateReportMutationVariables
    >(UpdateReportDocument, options);
}
export type UpdateReportMutationHookResult = ReturnType<
    typeof useUpdateReportMutation
>;
export type UpdateReportMutationResult =
    Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<
    UpdateReportMutation,
    UpdateReportMutationVariables
>;
export const UpdateTranscriptDocument = gql`
    mutation UpdateTranscript($input: SaveTranscriptChunkInput!) {
        updateTranscript(input: $input) {
            author
            createdAt
            end
            id
            lowQuality
            revisions {
                ...TranscriptRevision
            }
            start
            text
            updatedAt
        }
    }
    ${TranscriptRevisionFragmentDoc}
`;
export type UpdateTranscriptMutationFn = Apollo.MutationFunction<
    UpdateTranscriptMutation,
    UpdateTranscriptMutationVariables
>;

/**
 * __useUpdateTranscriptMutation__
 *
 * To run a mutation, you first call `useUpdateTranscriptMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranscriptMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTranscriptMutation, { data, loading, error }] = useUpdateTranscriptMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTranscriptMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateTranscriptMutation,
        UpdateTranscriptMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        UpdateTranscriptMutation,
        UpdateTranscriptMutationVariables
    >(UpdateTranscriptDocument, options);
}
export type UpdateTranscriptMutationHookResult = ReturnType<
    typeof useUpdateTranscriptMutation
>;
export type UpdateTranscriptMutationResult =
    Apollo.MutationResult<UpdateTranscriptMutation>;
export type UpdateTranscriptMutationOptions = Apollo.BaseMutationOptions<
    UpdateTranscriptMutation,
    UpdateTranscriptMutationVariables
>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
        updateUser(data: $input) {
            avatar
            email
            id
            name
            role
        }
    }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
    baseOptions?: Apollo.MutationHookOptions<
        UpdateUserMutation,
        UpdateUserMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        options,
    );
}
export type UpdateUserMutationHookResult = ReturnType<
    typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
    Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
>;
export const VerifyRecordingDocument = gql`
    mutation VerifyRecording(
        $comment: String
        $recordingId: Int!
        $status: VerificationStatus!
    ) {
        verifyRecording(
            comment: $comment
            recordingId: $recordingId
            status: $status
        ) {
            audio {
                ...Audio
            }
            createdAt
            id
            status
            transcriptVerificationStatus
            updatedAt
            verifications {
                ...Verification
            }
        }
    }
    ${AudioFragmentDoc}
    ${VerificationFragmentDoc}
`;
export type VerifyRecordingMutationFn = Apollo.MutationFunction<
    VerifyRecordingMutation,
    VerifyRecordingMutationVariables
>;

/**
 * __useVerifyRecordingMutation__
 *
 * To run a mutation, you first call `useVerifyRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyRecordingMutation, { data, loading, error }] = useVerifyRecordingMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      recordingId: // value for 'recordingId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useVerifyRecordingMutation(
    baseOptions?: Apollo.MutationHookOptions<
        VerifyRecordingMutation,
        VerifyRecordingMutationVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useMutation<
        VerifyRecordingMutation,
        VerifyRecordingMutationVariables
    >(VerifyRecordingDocument, options);
}
export type VerifyRecordingMutationHookResult = ReturnType<
    typeof useVerifyRecordingMutation
>;
export type VerifyRecordingMutationResult =
    Apollo.MutationResult<VerifyRecordingMutation>;
export type VerifyRecordingMutationOptions = Apollo.BaseMutationOptions<
    VerifyRecordingMutation,
    VerifyRecordingMutationVariables
>;
export const AuthMeDocument = gql`
    query AuthMe {
        me {
            avatar
            email
            id
            name
            role
        }
    }
`;

/**
 * __useAuthMeQuery__
 *
 * To run a query within a React component, call `useAuthMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthMeQuery(
    baseOptions?: Apollo.QueryHookOptions<AuthMeQuery, AuthMeQueryVariables>,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<AuthMeQuery, AuthMeQueryVariables>(
        AuthMeDocument,
        options,
    );
}
export function useAuthMeLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        AuthMeQuery,
        AuthMeQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<AuthMeQuery, AuthMeQueryVariables>(
        AuthMeDocument,
        options,
    );
}
export type AuthMeQueryHookResult = ReturnType<typeof useAuthMeQuery>;
export type AuthMeLazyQueryHookResult = ReturnType<typeof useAuthMeLazyQuery>;
export type AuthMeQueryResult = Apollo.QueryResult<
    AuthMeQuery,
    AuthMeQueryVariables
>;
export const GetNewTranscriptUrlDocument = gql`
    query GetNewTranscriptUrl(
        $end: Float!
        $recordingId: Int!
        $start: Float!
    ) {
        getNewTranscriptUrl(end: $end, recordingId: $recordingId, start: $start)
    }
`;

/**
 * __useGetNewTranscriptUrlQuery__
 *
 * To run a query within a React component, call `useGetNewTranscriptUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNewTranscriptUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNewTranscriptUrlQuery({
 *   variables: {
 *      end: // value for 'end'
 *      recordingId: // value for 'recordingId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetNewTranscriptUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetNewTranscriptUrlQuery,
        GetNewTranscriptUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetNewTranscriptUrlQuery,
        GetNewTranscriptUrlQueryVariables
    >(GetNewTranscriptUrlDocument, options);
}
export function useGetNewTranscriptUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetNewTranscriptUrlQuery,
        GetNewTranscriptUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetNewTranscriptUrlQuery,
        GetNewTranscriptUrlQueryVariables
    >(GetNewTranscriptUrlDocument, options);
}
export type GetNewTranscriptUrlQueryHookResult = ReturnType<
    typeof useGetNewTranscriptUrlQuery
>;
export type GetNewTranscriptUrlLazyQueryHookResult = ReturnType<
    typeof useGetNewTranscriptUrlLazyQuery
>;
export type GetNewTranscriptUrlQueryResult = Apollo.QueryResult<
    GetNewTranscriptUrlQuery,
    GetNewTranscriptUrlQueryVariables
>;
export const GetRecordingDocument = gql`
    query GetRecording($id: Int!) {
        getRecording(id: $id) {
            audio {
                ...Audio
            }
            verifications {
                comment
                createdAt
                id
                status
                updatedAt
                user {
                    ...User
                }
                userId
            }
            transcriptVerifiedBy {
                ...User
            }
            wholeTranscriptVerifiedBy {
                ...User
            }
            isWholeTranscriptVerified
            name
            isReportQueued
            createdAt
            id
            status
            transcriptStatus
            transcriptVerificationStatus
            updatedAt
            hasReport
        }
    }
    ${AudioFragmentDoc}
    ${UserFragmentDoc}
`;

/**
 * __useGetRecordingQuery__
 *
 * To run a query within a React component, call `useGetRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecordingQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetRecordingQuery,
        GetRecordingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetRecordingQuery, GetRecordingQueryVariables>(
        GetRecordingDocument,
        options,
    );
}
export function useGetRecordingLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetRecordingQuery,
        GetRecordingQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetRecordingQuery, GetRecordingQueryVariables>(
        GetRecordingDocument,
        options,
    );
}
export type GetRecordingQueryHookResult = ReturnType<
    typeof useGetRecordingQuery
>;
export type GetRecordingLazyQueryHookResult = ReturnType<
    typeof useGetRecordingLazyQuery
>;
export type GetRecordingQueryResult = Apollo.QueryResult<
    GetRecordingQuery,
    GetRecordingQueryVariables
>;
export const GetReportDocument = gql`
    query GetReport($recordingId: Float!) {
        getReport(recordingId: $recordingId) {
            allergies {
                description
                medications
                name
            }
            communityInterview {
                details
            }
            diagnosis {
                details
            }
            drugs {
                description
                frequency
                name
            }
            familyHistory {
                condition
                description
                relationship
            }
            generalAilments {
                description
                duration
                intensity
                name
            }
            patientInfo {
                name
                pesel
            }
            previousConditions {
                condition
                description
                medications
            }
            recommendations {
                items
                medications
            }
            services {
                eprescriptions
                ereferrals
                ezla
            }
            surgeryHospitalizations {
                cause
                description
                duration
            }
        }
    }
`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useGetReportQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetReportQuery,
        GetReportQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(
        GetReportDocument,
        options,
    );
}
export function useGetReportLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReportQuery,
        GetReportQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(
        GetReportDocument,
        options,
    );
}
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<
    typeof useGetReportLazyQuery
>;
export type GetReportQueryResult = Apollo.QueryResult<
    GetReportQuery,
    GetReportQueryVariables
>;
export const GetReportUrlDocument = gql`
    query GetReportUrl($recordingId: Int!) {
        getReportUrl(recordingId: $recordingId)
    }
`;

/**
 * __useGetReportUrlQuery__
 *
 * To run a query within a React component, call `useGetReportUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportUrlQuery({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *   },
 * });
 */
export function useGetReportUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetReportUrlQuery,
        GetReportUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetReportUrlQuery, GetReportUrlQueryVariables>(
        GetReportUrlDocument,
        options,
    );
}
export function useGetReportUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetReportUrlQuery,
        GetReportUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetReportUrlQuery, GetReportUrlQueryVariables>(
        GetReportUrlDocument,
        options,
    );
}
export type GetReportUrlQueryHookResult = ReturnType<
    typeof useGetReportUrlQuery
>;
export type GetReportUrlLazyQueryHookResult = ReturnType<
    typeof useGetReportUrlLazyQuery
>;
export type GetReportUrlQueryResult = Apollo.QueryResult<
    GetReportUrlQuery,
    GetReportUrlQueryVariables
>;
export const GetSessionsDocument = gql`
    query GetSessions {
        getSessions {
            text
            uuid
        }
    }
`;

/**
 * __useGetSessionsQuery__
 *
 * To run a query within a React component, call `useGetSessionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSessionsQuery(
    baseOptions?: Apollo.QueryHookOptions<
        GetSessionsQuery,
        GetSessionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<GetSessionsQuery, GetSessionsQueryVariables>(
        GetSessionsDocument,
        options,
    );
}
export function useGetSessionsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSessionsQuery,
        GetSessionsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<GetSessionsQuery, GetSessionsQueryVariables>(
        GetSessionsDocument,
        options,
    );
}
export type GetSessionsQueryHookResult = ReturnType<typeof useGetSessionsQuery>;
export type GetSessionsLazyQueryHookResult = ReturnType<
    typeof useGetSessionsLazyQuery
>;
export type GetSessionsQueryResult = Apollo.QueryResult<
    GetSessionsQuery,
    GetSessionsQueryVariables
>;
export const GetTranscriptUrlDocument = gql`
    query GetTranscriptUrl($id: Int!) {
        getTranscriptUrl(id: $id)
    }
`;

/**
 * __useGetTranscriptUrlQuery__
 *
 * To run a query within a React component, call `useGetTranscriptUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranscriptUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranscriptUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTranscriptUrlQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetTranscriptUrlQuery,
        GetTranscriptUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<
        GetTranscriptUrlQuery,
        GetTranscriptUrlQueryVariables
    >(GetTranscriptUrlDocument, options);
}
export function useGetTranscriptUrlLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetTranscriptUrlQuery,
        GetTranscriptUrlQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        GetTranscriptUrlQuery,
        GetTranscriptUrlQueryVariables
    >(GetTranscriptUrlDocument, options);
}
export type GetTranscriptUrlQueryHookResult = ReturnType<
    typeof useGetTranscriptUrlQuery
>;
export type GetTranscriptUrlLazyQueryHookResult = ReturnType<
    typeof useGetTranscriptUrlLazyQuery
>;
export type GetTranscriptUrlQueryResult = Apollo.QueryResult<
    GetTranscriptUrlQuery,
    GetTranscriptUrlQueryVariables
>;
export const ListRecordingsDocument = gql`
    query ListRecordings(
        $filters: Filters
        $skip: Int!
        $sortBy: RecordingSortableFields
        $sortOrder: SortOrder!
        $take: Int!
    ) {
        listRecordings(
            skip: $skip
            sortBy: $sortBy
            sortOrder: $sortOrder
            take: $take
            filters: $filters
        ) {
            data {
                audio {
                    ...Audio
                }
                verifications {
                    comment
                    createdAt
                    id
                    status
                    updatedAt
                    user {
                        ...User
                    }
                    userId
                }
                transcriptVerifiedBy {
                    ...User
                }
                wholeTranscriptVerifiedBy {
                    ...User
                }
                isWholeTranscriptVerified
                name
                isReportQueued
                createdAt
                id
                status
                transcriptStatus
                transcriptVerificationStatus
                updatedAt
                hasReport
            }
            skip
            take
            total
        }
    }
    ${AudioFragmentDoc}
    ${UserFragmentDoc}
`;

/**
 * __useListRecordingsQuery__
 *
 * To run a query within a React component, call `useListRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRecordingsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      skip: // value for 'skip'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListRecordingsQuery(
    baseOptions: Apollo.QueryHookOptions<
        ListRecordingsQuery,
        ListRecordingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ListRecordingsQuery, ListRecordingsQueryVariables>(
        ListRecordingsDocument,
        options,
    );
}
export function useListRecordingsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ListRecordingsQuery,
        ListRecordingsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ListRecordingsQuery,
        ListRecordingsQueryVariables
    >(ListRecordingsDocument, options);
}
export type ListRecordingsQueryHookResult = ReturnType<
    typeof useListRecordingsQuery
>;
export type ListRecordingsLazyQueryHookResult = ReturnType<
    typeof useListRecordingsLazyQuery
>;
export type ListRecordingsQueryResult = Apollo.QueryResult<
    ListRecordingsQuery,
    ListRecordingsQueryVariables
>;
export const ListTranscriptsDocument = gql`
    query ListTranscripts(
        $recordingId: Int!
        $skip: Int!
        $sortBy: TranscriptSortableFields
        $sortOrder: SortOrder!
        $take: Int!
    ) {
        listTranscripts(
            recordingId: $recordingId
            skip: $skip
            sortBy: $sortBy
            sortOrder: $sortOrder
            take: $take
        ) {
            data {
                author
                createdAt
                end
                id
                revisions {
                    ...TranscriptRevision
                }
                start
                text
                updatedAt
                lowQuality
            }
            skip
            take
            total
        }
    }
    ${TranscriptRevisionFragmentDoc}
`;

/**
 * __useListTranscriptsQuery__
 *
 * To run a query within a React component, call `useListTranscriptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTranscriptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTranscriptsQuery({
 *   variables: {
 *      recordingId: // value for 'recordingId'
 *      skip: // value for 'skip'
 *      sortBy: // value for 'sortBy'
 *      sortOrder: // value for 'sortOrder'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListTranscriptsQuery(
    baseOptions: Apollo.QueryHookOptions<
        ListTranscriptsQuery,
        ListTranscriptsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useQuery<ListTranscriptsQuery, ListTranscriptsQueryVariables>(
        ListTranscriptsDocument,
        options,
    );
}
export function useListTranscriptsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        ListTranscriptsQuery,
        ListTranscriptsQueryVariables
    >,
) {
    const options = { ...defaultOptions, ...baseOptions };
    return Apollo.useLazyQuery<
        ListTranscriptsQuery,
        ListTranscriptsQueryVariables
    >(ListTranscriptsDocument, options);
}
export type ListTranscriptsQueryHookResult = ReturnType<
    typeof useListTranscriptsQuery
>;
export type ListTranscriptsLazyQueryHookResult = ReturnType<
    typeof useListTranscriptsLazyQuery
>;
export type ListTranscriptsQueryResult = Apollo.QueryResult<
    ListTranscriptsQuery,
    ListTranscriptsQueryVariables
>;
export type AllergyKeySpecifier = (
    | 'description'
    | 'medications'
    | 'name'
    | AllergyKeySpecifier
)[];
export type AllergyFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>;
    medications?: FieldPolicy<any> | FieldReadFunction<any>;
    name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AudioKeySpecifier = (
    | 'createdAt'
    | 'id'
    | 'mimeType'
    | 'name'
    | 'size'
    | 'updatedAt'
    | 'url'
    | AudioKeySpecifier
)[];
export type AudioFieldPolicy = {
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    mimeType?: FieldPolicy<any> | FieldReadFunction<any>;
    name?: FieldPolicy<any> | FieldReadFunction<any>;
    size?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DrugKeySpecifier = (
    | 'description'
    | 'frequency'
    | 'name'
    | DrugKeySpecifier
)[];
export type DrugFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>;
    frequency?: FieldPolicy<any> | FieldReadFunction<any>;
    name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FamilyHistoryKeySpecifier = (
    | 'condition'
    | 'description'
    | 'relationship'
    | FamilyHistoryKeySpecifier
)[];
export type FamilyHistoryFieldPolicy = {
    condition?: FieldPolicy<any> | FieldReadFunction<any>;
    description?: FieldPolicy<any> | FieldReadFunction<any>;
    relationship?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GeneralAilmentKeySpecifier = (
    | 'description'
    | 'duration'
    | 'intensity'
    | 'name'
    | GeneralAilmentKeySpecifier
)[];
export type GeneralAilmentFieldPolicy = {
    description?: FieldPolicy<any> | FieldReadFunction<any>;
    duration?: FieldPolicy<any> | FieldReadFunction<any>;
    intensity?: FieldPolicy<any> | FieldReadFunction<any>;
    name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListRecordingsResponseKeySpecifier = (
    | 'data'
    | 'skip'
    | 'take'
    | 'total'
    | ListRecordingsResponseKeySpecifier
)[];
export type ListRecordingsResponseFieldPolicy = {
    data?: FieldPolicy<any> | FieldReadFunction<any>;
    skip?: FieldPolicy<any> | FieldReadFunction<any>;
    take?: FieldPolicy<any> | FieldReadFunction<any>;
    total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ListTranscriptsResponseKeySpecifier = (
    | 'data'
    | 'skip'
    | 'take'
    | 'total'
    | ListTranscriptsResponseKeySpecifier
)[];
export type ListTranscriptsResponseFieldPolicy = {
    data?: FieldPolicy<any> | FieldReadFunction<any>;
    skip?: FieldPolicy<any> | FieldReadFunction<any>;
    take?: FieldPolicy<any> | FieldReadFunction<any>;
    total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
    | 'addTemplates'
    | 'createRecording'
    | 'createSession'
    | 'loginWithGoogle'
    | 'logout'
    | 'markTranscriptVerified'
    | 'requestReport'
    | 'requestTranscript'
    | 'saveTranscript'
    | 'updateReport'
    | 'updateTranscript'
    | 'updateUser'
    | 'verifyRecording'
    | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
    addTemplates?: FieldPolicy<any> | FieldReadFunction<any>;
    createRecording?: FieldPolicy<any> | FieldReadFunction<any>;
    createSession?: FieldPolicy<any> | FieldReadFunction<any>;
    loginWithGoogle?: FieldPolicy<any> | FieldReadFunction<any>;
    logout?: FieldPolicy<any> | FieldReadFunction<any>;
    markTranscriptVerified?: FieldPolicy<any> | FieldReadFunction<any>;
    requestReport?: FieldPolicy<any> | FieldReadFunction<any>;
    requestTranscript?: FieldPolicy<any> | FieldReadFunction<any>;
    saveTranscript?: FieldPolicy<any> | FieldReadFunction<any>;
    updateReport?: FieldPolicy<any> | FieldReadFunction<any>;
    updateTranscript?: FieldPolicy<any> | FieldReadFunction<any>;
    updateUser?: FieldPolicy<any> | FieldReadFunction<any>;
    verifyRecording?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PatientInfoKeySpecifier = (
    | 'name'
    | 'pesel'
    | PatientInfoKeySpecifier
)[];
export type PatientInfoFieldPolicy = {
    name?: FieldPolicy<any> | FieldReadFunction<any>;
    pesel?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PreviousConditionKeySpecifier = (
    | 'condition'
    | 'description'
    | 'medications'
    | PreviousConditionKeySpecifier
)[];
export type PreviousConditionFieldPolicy = {
    condition?: FieldPolicy<any> | FieldReadFunction<any>;
    description?: FieldPolicy<any> | FieldReadFunction<any>;
    medications?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
    | 'getNewTranscriptUrl'
    | 'getRecording'
    | 'getReport'
    | 'getReportUrl'
    | 'getSessions'
    | 'getTranscriptUrl'
    | 'listRecordings'
    | 'listTranscripts'
    | 'me'
    | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
    getNewTranscriptUrl?: FieldPolicy<any> | FieldReadFunction<any>;
    getRecording?: FieldPolicy<any> | FieldReadFunction<any>;
    getReport?: FieldPolicy<any> | FieldReadFunction<any>;
    getReportUrl?: FieldPolicy<any> | FieldReadFunction<any>;
    getSessions?: FieldPolicy<any> | FieldReadFunction<any>;
    getTranscriptUrl?: FieldPolicy<any> | FieldReadFunction<any>;
    listRecordings?: FieldPolicy<any> | FieldReadFunction<any>;
    listTranscripts?: FieldPolicy<any> | FieldReadFunction<any>;
    me?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecommendationKeySpecifier = (
    | 'items'
    | 'medications'
    | RecommendationKeySpecifier
)[];
export type RecommendationFieldPolicy = {
    items?: FieldPolicy<any> | FieldReadFunction<any>;
    medications?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecordingKeySpecifier = (
    | 'audio'
    | 'createdAt'
    | 'hasReport'
    | 'id'
    | 'isReportQueued'
    | 'isWholeTranscriptVerified'
    | 'name'
    | 'status'
    | 'transcriptStatus'
    | 'transcriptVerificationStatus'
    | 'transcriptVerifiedBy'
    | 'updatedAt'
    | 'verifications'
    | 'wholeTranscriptVerifiedBy'
    | RecordingKeySpecifier
)[];
export type RecordingFieldPolicy = {
    audio?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    hasReport?: FieldPolicy<any> | FieldReadFunction<any>;
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    isReportQueued?: FieldPolicy<any> | FieldReadFunction<any>;
    isWholeTranscriptVerified?: FieldPolicy<any> | FieldReadFunction<any>;
    name?: FieldPolicy<any> | FieldReadFunction<any>;
    status?: FieldPolicy<any> | FieldReadFunction<any>;
    transcriptStatus?: FieldPolicy<any> | FieldReadFunction<any>;
    transcriptVerificationStatus?: FieldPolicy<any> | FieldReadFunction<any>;
    transcriptVerifiedBy?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    verifications?: FieldPolicy<any> | FieldReadFunction<any>;
    wholeTranscriptVerifiedBy?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RecordingSessionKeySpecifier = (
    | 'text'
    | 'uuid'
    | RecordingSessionKeySpecifier
)[];
export type RecordingSessionFieldPolicy = {
    text?: FieldPolicy<any> | FieldReadFunction<any>;
    uuid?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportKeySpecifier = (
    | 'allergies'
    | 'communityInterview'
    | 'diagnosis'
    | 'drugs'
    | 'familyHistory'
    | 'generalAilments'
    | 'patientInfo'
    | 'previousConditions'
    | 'recommendations'
    | 'services'
    | 'surgeryHospitalizations'
    | ReportKeySpecifier
)[];
export type ReportFieldPolicy = {
    allergies?: FieldPolicy<any> | FieldReadFunction<any>;
    communityInterview?: FieldPolicy<any> | FieldReadFunction<any>;
    diagnosis?: FieldPolicy<any> | FieldReadFunction<any>;
    drugs?: FieldPolicy<any> | FieldReadFunction<any>;
    familyHistory?: FieldPolicy<any> | FieldReadFunction<any>;
    generalAilments?: FieldPolicy<any> | FieldReadFunction<any>;
    patientInfo?: FieldPolicy<any> | FieldReadFunction<any>;
    previousConditions?: FieldPolicy<any> | FieldReadFunction<any>;
    recommendations?: FieldPolicy<any> | FieldReadFunction<any>;
    services?: FieldPolicy<any> | FieldReadFunction<any>;
    surgeryHospitalizations?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ServicesKeySpecifier = (
    | 'eprescriptions'
    | 'ereferrals'
    | 'ezla'
    | ServicesKeySpecifier
)[];
export type ServicesFieldPolicy = {
    eprescriptions?: FieldPolicy<any> | FieldReadFunction<any>;
    ereferrals?: FieldPolicy<any> | FieldReadFunction<any>;
    ezla?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SingleValueFieldKeySpecifier = (
    | 'details'
    | SingleValueFieldKeySpecifier
)[];
export type SingleValueFieldFieldPolicy = {
    details?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SurgeryHospitalizationKeySpecifier = (
    | 'cause'
    | 'description'
    | 'duration'
    | SurgeryHospitalizationKeySpecifier
)[];
export type SurgeryHospitalizationFieldPolicy = {
    cause?: FieldPolicy<any> | FieldReadFunction<any>;
    description?: FieldPolicy<any> | FieldReadFunction<any>;
    duration?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TranscriptKeySpecifier = (
    | 'author'
    | 'createdAt'
    | 'end'
    | 'id'
    | 'lowQuality'
    | 'revisions'
    | 'start'
    | 'text'
    | 'updatedAt'
    | TranscriptKeySpecifier
)[];
export type TranscriptFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    end?: FieldPolicy<any> | FieldReadFunction<any>;
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    lowQuality?: FieldPolicy<any> | FieldReadFunction<any>;
    revisions?: FieldPolicy<any> | FieldReadFunction<any>;
    start?: FieldPolicy<any> | FieldReadFunction<any>;
    text?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TranscriptRevisionKeySpecifier = (
    | 'author'
    | 'createdAt'
    | 'end'
    | 'id'
    | 'lowQuality'
    | 'start'
    | 'text'
    | 'updatedAt'
    | 'user'
    | TranscriptRevisionKeySpecifier
)[];
export type TranscriptRevisionFieldPolicy = {
    author?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    end?: FieldPolicy<any> | FieldReadFunction<any>;
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    lowQuality?: FieldPolicy<any> | FieldReadFunction<any>;
    start?: FieldPolicy<any> | FieldReadFunction<any>;
    text?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
    | 'avatar'
    | 'email'
    | 'id'
    | 'name'
    | 'role'
    | UserKeySpecifier
)[];
export type UserFieldPolicy = {
    avatar?: FieldPolicy<any> | FieldReadFunction<any>;
    email?: FieldPolicy<any> | FieldReadFunction<any>;
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    name?: FieldPolicy<any> | FieldReadFunction<any>;
    role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VerificationKeySpecifier = (
    | 'comment'
    | 'createdAt'
    | 'id'
    | 'status'
    | 'updatedAt'
    | 'user'
    | 'userId'
    | VerificationKeySpecifier
)[];
export type VerificationFieldPolicy = {
    comment?: FieldPolicy<any> | FieldReadFunction<any>;
    createdAt?: FieldPolicy<any> | FieldReadFunction<any>;
    id?: FieldPolicy<any> | FieldReadFunction<any>;
    status?: FieldPolicy<any> | FieldReadFunction<any>;
    updatedAt?: FieldPolicy<any> | FieldReadFunction<any>;
    user?: FieldPolicy<any> | FieldReadFunction<any>;
    userId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
    Allergy?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AllergyKeySpecifier
            | (() => undefined | AllergyKeySpecifier);
        fields?: AllergyFieldPolicy;
    };
    Audio?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | AudioKeySpecifier
            | (() => undefined | AudioKeySpecifier);
        fields?: AudioFieldPolicy;
    };
    Drug?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | DrugKeySpecifier
            | (() => undefined | DrugKeySpecifier);
        fields?: DrugFieldPolicy;
    };
    FamilyHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | FamilyHistoryKeySpecifier
            | (() => undefined | FamilyHistoryKeySpecifier);
        fields?: FamilyHistoryFieldPolicy;
    };
    GeneralAilment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | GeneralAilmentKeySpecifier
            | (() => undefined | GeneralAilmentKeySpecifier);
        fields?: GeneralAilmentFieldPolicy;
    };
    ListRecordingsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ListRecordingsResponseKeySpecifier
            | (() => undefined | ListRecordingsResponseKeySpecifier);
        fields?: ListRecordingsResponseFieldPolicy;
    };
    ListTranscriptsResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ListTranscriptsResponseKeySpecifier
            | (() => undefined | ListTranscriptsResponseKeySpecifier);
        fields?: ListTranscriptsResponseFieldPolicy;
    };
    Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | MutationKeySpecifier
            | (() => undefined | MutationKeySpecifier);
        fields?: MutationFieldPolicy;
    };
    PatientInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PatientInfoKeySpecifier
            | (() => undefined | PatientInfoKeySpecifier);
        fields?: PatientInfoFieldPolicy;
    };
    PreviousCondition?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | PreviousConditionKeySpecifier
            | (() => undefined | PreviousConditionKeySpecifier);
        fields?: PreviousConditionFieldPolicy;
    };
    Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | QueryKeySpecifier
            | (() => undefined | QueryKeySpecifier);
        fields?: QueryFieldPolicy;
    };
    Recommendation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RecommendationKeySpecifier
            | (() => undefined | RecommendationKeySpecifier);
        fields?: RecommendationFieldPolicy;
    };
    Recording?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RecordingKeySpecifier
            | (() => undefined | RecordingKeySpecifier);
        fields?: RecordingFieldPolicy;
    };
    RecordingSession?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | RecordingSessionKeySpecifier
            | (() => undefined | RecordingSessionKeySpecifier);
        fields?: RecordingSessionFieldPolicy;
    };
    Report?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ReportKeySpecifier
            | (() => undefined | ReportKeySpecifier);
        fields?: ReportFieldPolicy;
    };
    Services?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | ServicesKeySpecifier
            | (() => undefined | ServicesKeySpecifier);
        fields?: ServicesFieldPolicy;
    };
    SingleValueField?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SingleValueFieldKeySpecifier
            | (() => undefined | SingleValueFieldKeySpecifier);
        fields?: SingleValueFieldFieldPolicy;
    };
    SurgeryHospitalization?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | SurgeryHospitalizationKeySpecifier
            | (() => undefined | SurgeryHospitalizationKeySpecifier);
        fields?: SurgeryHospitalizationFieldPolicy;
    };
    Transcript?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | TranscriptKeySpecifier
            | (() => undefined | TranscriptKeySpecifier);
        fields?: TranscriptFieldPolicy;
    };
    TranscriptRevision?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | TranscriptRevisionKeySpecifier
            | (() => undefined | TranscriptRevisionKeySpecifier);
        fields?: TranscriptRevisionFieldPolicy;
    };
    User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | UserKeySpecifier
            | (() => undefined | UserKeySpecifier);
        fields?: UserFieldPolicy;
    };
    Verification?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
        keyFields?:
            | false
            | VerificationKeySpecifier
            | (() => undefined | VerificationKeySpecifier);
        fields?: VerificationFieldPolicy;
    };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
export const OPERATIONS = {
    Query: {
        AuthMe: 'AuthMe' as const,
        GetNewTranscriptUrl: 'GetNewTranscriptUrl' as const,
        GetRecording: 'GetRecording' as const,
        GetReport: 'GetReport' as const,
        GetReportUrl: 'GetReportUrl' as const,
        GetSessions: 'GetSessions' as const,
        GetTranscriptUrl: 'GetTranscriptUrl' as const,
        ListRecordings: 'ListRecordings' as const,
        ListTranscripts: 'ListTranscripts' as const,
    },
    Mutation: {
        CreateRecording: 'CreateRecording' as const,
        CreateSession: 'CreateSession' as const,
        LoginWithGoogle: 'LoginWithGoogle' as const,
        Logout: 'Logout' as const,
        MarkTranscriptVerified: 'MarkTranscriptVerified' as const,
        RequestReport: 'RequestReport' as const,
        RequestTranscript: 'RequestTranscript' as const,
        SaveTranscript: 'SaveTranscript' as const,
        UpdateReport: 'UpdateReport' as const,
        UpdateTranscript: 'UpdateTranscript' as const,
        UpdateUser: 'UpdateUser' as const,
        VerifyRecording: 'VerifyRecording' as const,
    },
    Fragment: {
        Audio: 'Audio' as const,
        Transcript: 'Transcript' as const,
        TranscriptRevision: 'TranscriptRevision' as const,
        User: 'User' as const,
        Verification: 'Verification' as const,
    },
};
export const AudioFragmentTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Audio' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Audio' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mimeType' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AudioFragment, unknown>;
export const UserFragmentTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UserFragment, unknown>;
export const TranscriptRevisionFragmentTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TranscriptRevision' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TranscriptRevision' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TranscriptRevisionFragment, unknown>;
export const TranscriptFragmentTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Transcript' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Transcript' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'revisions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: {
                                        kind: 'Name',
                                        value: 'TranscriptRevision',
                                    },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TranscriptRevision' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TranscriptRevision' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TranscriptFragment, unknown>;
export const VerificationFragmentTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Verification' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Verification' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<VerificationFragment, unknown>;
export const CreateRecordingTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CreateRecording' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Upload' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'session' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'RecordingSessionConfigInput',
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createRecording' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'file' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'file' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'session' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'session' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateRecordingMutation,
    CreateRecordingMutationVariables
>;
export const CreateSessionTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'CreateSession' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createSession' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uuid' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    CreateSessionMutation,
    CreateSessionMutationVariables
>;
export const LoginWithGoogleTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'LoginWithGoogle' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'loginWithGoogle' },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
>;
export const LogoutTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'Logout' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'logout' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const MarkTranscriptVerifiedTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'MarkTranscriptVerified' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'verified' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Boolean' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'markTranscriptVerified' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'verified' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'verified' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'audio' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'Audio',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'verifications',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'comment',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'createdAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'status',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updatedAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'User',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'userId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isReportQueued',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptVerificationStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptVerificationStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasReport' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Audio' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Audio' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mimeType' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    MarkTranscriptVerifiedMutation,
    MarkTranscriptVerifiedMutationVariables
>;
export const RequestReportTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'RequestReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestReport' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RequestReportMutation,
    RequestReportMutationVariables
>;
export const RequestTranscriptTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'RequestTranscript' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'requestTranscript' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RequestTranscriptMutation,
    RequestTranscriptMutationVariables
>;
export const SaveTranscriptTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'SaveTranscript' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'SaveTranscriptInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'saveTranscript' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revisions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'TranscriptRevision',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TranscriptRevision' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TranscriptRevision' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    SaveTranscriptMutation,
    SaveTranscriptMutationVariables
>;
export const UpdateReportTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'data' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'ReportInput' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateReport' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'data' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allergies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'medications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'communityInterview',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'details',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'diagnosis' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'details',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'drugs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'frequency',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'familyHistory',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'condition',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'relationship',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'generalAilments',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'duration',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'intensity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'patientInfo',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pesel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'previousConditions',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'condition',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'medications',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'recommendations',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'items',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'medications',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'eprescriptions',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ereferrals',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ezla',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'surgeryHospitalizations',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cause',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'duration',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateReportMutation,
    UpdateReportMutationVariables
>;
export const UpdateTranscriptTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateTranscript' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: {
                                kind: 'Name',
                                value: 'SaveTranscriptChunkInput',
                            },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateTranscript' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'input' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'author' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'lowQuality' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'revisions' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'TranscriptRevision',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TranscriptRevision' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TranscriptRevision' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UpdateTranscriptMutation,
    UpdateTranscriptMutationVariables
>;
export const UpdateUserTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'UpdateUser' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'UpdateUserInput' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updateUser' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'data' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'input' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
export const VerifyRecordingTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'mutation',
            name: { kind: 'Name', value: 'VerifyRecording' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'comment' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'String' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'status' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'VerificationStatus' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'verifyRecording' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'comment' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'comment' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'status' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'status' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'audio' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'Audio',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptVerificationStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'verifications',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'Verification',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Audio' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Audio' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mimeType' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Verification' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Verification' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'comment' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    VerifyRecordingMutation,
    VerifyRecordingMutationVariables
>;
export const AuthMeTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'AuthMe' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'me' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'avatar' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'email' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'role' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AuthMeQuery, AuthMeQueryVariables>;
export const GetNewTranscriptUrlTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetNewTranscriptUrl' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'end' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'start' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getNewTranscriptUrl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'end' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'end' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'start' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'start' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetNewTranscriptUrlQuery,
    GetNewTranscriptUrlQueryVariables
>;
export const GetRecordingTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetRecording' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getRecording' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'audio' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'Audio',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'verifications',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'comment',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'createdAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'status',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updatedAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'user',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'User',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'userId',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptVerifiedBy',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'User',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'wholeTranscriptVerifiedBy',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'FragmentSpread',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'User',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isWholeTranscriptVerified',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'isReportQueued',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'createdAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'transcriptVerificationStatus',
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'updatedAt' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'hasReport' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Audio' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Audio' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mimeType' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetRecordingQuery, GetRecordingQueryVariables>;
export const GetReportTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetReport' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Float' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getReport' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'allergies' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'medications',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'communityInterview',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'details',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'diagnosis' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'details',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'drugs' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'frequency',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'familyHistory',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'condition',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'relationship',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'generalAilments',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'duration',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'intensity',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'patientInfo',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'pesel',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'previousConditions',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'condition',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'medications',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'recommendations',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'items',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'medications',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'services' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'eprescriptions',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ereferrals',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'ezla',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: {
                                        kind: 'Name',
                                        value: 'surgeryHospitalizations',
                                    },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'cause',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'description',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'duration',
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetReportQuery, GetReportQueryVariables>;
export const GetReportUrlTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetReportUrl' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getReportUrl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetReportUrlQuery, GetReportUrlQueryVariables>;
export const GetSessionsTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetSessions' },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getSessions' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'text' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'uuid' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<GetSessionsQuery, GetSessionsQueryVariables>;
export const GetTranscriptUrlTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'GetTranscriptUrl' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'getTranscriptUrl' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'id' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'id' },
                                },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    GetTranscriptUrlQuery,
    GetTranscriptUrlQueryVariables
>;
export const ListRecordingsTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ListRecordings' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'filters' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: { kind: 'Name', value: 'Filters' },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skip' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortBy' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'RecordingSortableFields',
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortOrder' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SortOrder' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'take' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listRecordings' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'skip' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skip' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sortBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sortBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sortOrder' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sortOrder' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'take' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'take' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'filters' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'filters' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'audio',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'Audio',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'verifications',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'comment',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'createdAt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'id',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'status',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'updatedAt',
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'user',
                                                            },
                                                            selectionSet: {
                                                                kind: 'SelectionSet',
                                                                selections: [
                                                                    {
                                                                        kind: 'FragmentSpread',
                                                                        name: {
                                                                            kind: 'Name',
                                                                            value: 'User',
                                                                        },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                        {
                                                            kind: 'Field',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'userId',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'transcriptVerifiedBy',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'User',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'wholeTranscriptVerifiedBy',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'User',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isWholeTranscriptVerified',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'name',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'isReportQueued',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'createdAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'status',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'transcriptStatus',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'transcriptVerificationStatus',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updatedAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'hasReport',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skip' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'take' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'Audio' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'Audio' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mimeType' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListRecordingsQuery, ListRecordingsQueryVariables>;
export const ListTranscriptsTyped = {
    kind: 'Document',
    definitions: [
        {
            kind: 'OperationDefinition',
            operation: 'query',
            name: { kind: 'Name', value: 'ListTranscripts' },
            variableDefinitions: [
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'recordingId' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'skip' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortBy' },
                    },
                    type: {
                        kind: 'NamedType',
                        name: {
                            kind: 'Name',
                            value: 'TranscriptSortableFields',
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'sortOrder' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'SortOrder' },
                        },
                    },
                },
                {
                    kind: 'VariableDefinition',
                    variable: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'take' },
                    },
                    type: {
                        kind: 'NonNullType',
                        type: {
                            kind: 'NamedType',
                            name: { kind: 'Name', value: 'Int' },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'listTranscripts' },
                        arguments: [
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'recordingId' },
                                value: {
                                    kind: 'Variable',
                                    name: {
                                        kind: 'Name',
                                        value: 'recordingId',
                                    },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'skip' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'skip' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sortBy' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sortBy' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'sortOrder' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'sortOrder' },
                                },
                            },
                            {
                                kind: 'Argument',
                                name: { kind: 'Name', value: 'take' },
                                value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'take' },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'data' },
                                    selectionSet: {
                                        kind: 'SelectionSet',
                                        selections: [
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'author',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'createdAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'end',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'id',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'revisions',
                                                },
                                                selectionSet: {
                                                    kind: 'SelectionSet',
                                                    selections: [
                                                        {
                                                            kind: 'FragmentSpread',
                                                            name: {
                                                                kind: 'Name',
                                                                value: 'TranscriptRevision',
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'start',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'text',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'updatedAt',
                                                },
                                            },
                                            {
                                                kind: 'Field',
                                                name: {
                                                    kind: 'Name',
                                                    value: 'lowQuality',
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'skip' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'take' },
                                },
                                {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'total' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'User' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'User' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'avatar' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'role' } },
                ],
            },
        },
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'TranscriptRevision' },
            typeCondition: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'TranscriptRevision' },
            },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'createdAt' },
                    },
                    { kind: 'Field', name: { kind: 'Name', value: 'end' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'updatedAt' },
                    },
                    {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                            kind: 'SelectionSet',
                            selections: [
                                {
                                    kind: 'FragmentSpread',
                                    name: { kind: 'Name', value: 'User' },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    ListTranscriptsQuery,
    ListTranscriptsQueryVariables
>;
