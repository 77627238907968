import FormLogoImg from 'assets/images/form_logo.png';
import { notify } from 'components/ToastNotification/ToastNotification';
import { customStyles } from 'components/_form-elements/Select/Select.styled';
import { IconManager } from 'components/_universal/IconManager/IconManager';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import {
    useGetReportLazyQuery,
    useUpdateReportMutation,
} from 'src/graphql/generated';
import {
    selectFormData,
    selectRecordingId,
    setFormData,
} from 'state/redux/slices/app';
import { dispatch } from 'state/redux/store';
import { ToastTypes } from 'types/CommonTypes';
import {
    CheckboxWrapper,
    CloseIconWrapper,
    Divider,
    FormBodyWrapper,
    FormBox,
    FormHeaderWrapper,
    FormInfoBlueText,
    FormInfoInnerWrapper,
    FormInfoText,
    FormInfoWrapper,
    FormLogo,
    FormTitle,
    FormWrapper,
    Input,
    InputWrapper,
    InsideWrapper,
    List,
    ListItem,
    SectionSubtitle,
    SectionText,
    SectionTitle,
    Spacing,
    StyledButton,
    StyledInput,
    SymptomsInnerWrapper,
    SymptomsWrapper,
} from './Form.styled';
import { AddNewField } from './components/AddNewField/AddNewField';
import Checkbox from './components/Checkbox/Checkbox';
import { EditableSection } from './components/EditableSection/EditableSection';
import NewDrug from './components/NewDrug/NewDrug';
import {
    NewFamilyHistory,
    relationshipOptions,
} from './components/NewFamilyHistory/NewFamilyHistory';
import NewGeneralAilment from './components/NewGeneralAilment/NewGeneralAilment';
import NewRecommendation from './components/NewRecommendation/NewRecommendation';
import NewSurgeryHospitalization from './components/NewSurgeryHospitalization/NewSurgeryHospitalization';
import { RadioButton } from './components/RadioButton/RadioButton';
import AutoResizeTextarea from './components/StyledTextarea/StyledTextarea';
import {
    DrugsFields,
    FamilyHistoryFields,
    FormValues,
    GeneralAilmentFields,
    RecommendationFields,
    SingleValueFieldInput,
    SurgeryHospitalizationsFields,
} from './types';

const options = [
    { label: 'słabe', value: 1 },
    { label: 'znaczne', value: 2 },
    { label: 'silne', value: 3 },
];

export const Form = () => {
    const formData = useSelector(selectFormData);
    const recordingId = useSelector(selectRecordingId);
    const [updateReport] = useUpdateReportMutation();
    const [getReport, { data }] = useGetReportLazyQuery({
        variables: { recordingId: recordingId },
        onError: (error) => {
            return notify('Błąd ściągania formularza: ' + error.message)[
                ToastTypes.ERROR
            ]();
        },
        fetchPolicy: 'network-only',
    });

    const [editStates, setEditStates] = useState<{ [key: string]: boolean }>({
        allergies: false,
        communityInterview: false,
        diagnosis: false,
        drugs: false,
        familyHistory: false,
        generalAilments: false,
        patientInfo: false,
        previousConditions: false,
        recommendations: false,
        surgeryHospitalizations: false,
        issuedDocuments: false,
    });

    const [newGeneralAilments, setNewGeneralAilments] = useState<
        GeneralAilmentFields[]
    >([]);
    const [newSurgeryHospitalizations, setNewSurgeryHospitalizations] =
        useState<SurgeryHospitalizationsFields[]>([]);
    const [newFamilyHistory, setNewFamilyHistory] = useState<
        FamilyHistoryFields[]
    >([]);
    const [newDrugs, setNewDrugs] = useState<DrugsFields[]>([]);
    const [newCommunityInterview, setNewCommunityInterview] = useState<
        SingleValueFieldInput[]
    >([]);
    const [newDiagnosis, setNewDiagnosis] = useState<SingleValueFieldInput[]>(
        [],
    );
    const [newRecommendations, setNewRecommendations] = useState<
        RecommendationFields[]
    >([]);

    const { register, formState, handleSubmit, setValue, watch } =
        useForm<FormValues>({
            defaultValues: data ? data?.getReport : formData,
        });

    const watchEprescriptions = watch('services.eprescriptions');
    const watchEreferrals = watch('services.ereferrals');
    const watchEzla = watch('services.ezla');

    const handleCheckboxChange = (
        field:
            | 'services.eprescriptions'
            | 'services.ereferrals'
            | 'services.ezla',
    ) => {
        return (event: React.ChangeEvent<HTMLInputElement>) => {
            setValue(field, event.target.checked, { shouldDirty: true });
        };
    };

    const toggleEditing = (field: string) => {
        setEditStates((prev) => ({ ...prev, [field]: !prev[field] }));
    };

    const handleNewAilmentChange = (
        index: number,
        field: string,
        value: any,
    ) => {
        setNewGeneralAilments((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item,
            ),
        );
    };

    const addNewAilment = () => {
        setNewGeneralAilments((prev) => [
            ...prev,
            { name: '', description: '', intensity: 1, duration: '' },
        ]);
    };

    const removeNewAilment = (index: number) => {
        setNewGeneralAilments((prev) => prev.filter((_, idx) => idx !== index));
    };

    const handleNewSurgeryHospitalizationChange = (
        index: number,
        field: string,
        value: any,
    ) => {
        setNewSurgeryHospitalizations((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item,
            ),
        );
    };

    const addNewSurgeryHospitalization = () => {
        setNewSurgeryHospitalizations((prev) => [
            ...prev,
            { cause: '', description: '', duration: '' },
        ]);
    };

    const removeNewSurgeryHospitalization = (index: number) => {
        setNewSurgeryHospitalizations((prev) =>
            prev.filter((_, idx) => idx !== index),
        );
    };

    const handleNewFamilyHistoryChange = (
        index: number,
        field: string,
        value: any,
    ) => {
        setNewFamilyHistory((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item,
            ),
        );
    };

    const addNewFamilyHistory = () => {
        setNewFamilyHistory((prev) => [
            ...prev,
            { condition: '', description: '', relationship: 'FIRST_DEGREE' },
        ]);
    };

    const removeNewFamilyHistory = (index: number) => {
        setNewFamilyHistory((prev) => prev.filter((_, idx) => idx !== index));
    };

    const handleNewDrugChange = (index: number, field: string, value: any) => {
        setNewDrugs((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item,
            ),
        );
    };

    const addNewDrug = () => {
        setNewDrugs((prev) => [
            ...prev,
            { name: '', frequency: '', description: '' },
        ]);
    };

    const removeNewDrug = (index: number) => {
        setNewDrugs((prev) => prev.filter((_, idx) => idx !== index));
    };

    const handleSingleValueFieldChange = (
        field: keyof FormValues,
        value: any,
    ) => {
        setValue(field, value, { shouldDirty: true });
    };

    const handleInputChange = <T extends keyof FormValues>(
        field: T,
        value: any,
    ) => {
        setValue(field, value, { shouldDirty: true });
    };

    const handleNewCommunityInterviewChange = (
        index: number,
        value: string,
    ) => {
        setNewCommunityInterview((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { details: value } : item,
            ),
        );
    };

    const addNewCommunityInterview = () => {
        setNewCommunityInterview((prev) => [...prev, { details: '' }]);
    };

    const handleNewDiagnosisChange = (index: number, value: string) => {
        setNewDiagnosis((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { details: value } : item,
            ),
        );
    };

    const addNewDiagnosis = () => {
        setNewDiagnosis((prev) => [...prev, { details: '' }]);
    };

    const handleNewRecommendationChange = (
        index: number,
        field: string,
        value: any,
    ) => {
        setNewRecommendations((prev) =>
            prev?.map((item, idx) =>
                idx === index ? { ...item, [field]: value } : item,
            ),
        );
    };

    const addNewRecommendation = () => {
        setNewRecommendations((prev) => [
            ...prev,
            { items: [''], medications: [''] },
        ]);
    };

    const removeNewRecommendation = (index: number) => {
        setNewRecommendations((prev) => prev.filter((_, idx) => idx !== index));
    };

    const getRelationshipLabel = (value: string) => {
        const option = relationshipOptions.find(
            (option) => option.value === value,
        );
        return option ? option.label : '';
    };

    const filterEmptyStrings = (data: any): any => {
        if (Array.isArray(data)) {
            return data
                ?.map((item) =>
                    item && typeof item === 'object'
                        ? filterEmptyStrings(item)
                        : item,
                )
                .filter((item) => item !== '');
        } else if (data && typeof data === 'object') {
            return Object.fromEntries(
                Object.entries(data)
                    ?.map(([key, value]) => [key, filterEmptyStrings(value)])
                    .filter(
                        ([, value]) =>
                            value !== '' &&
                            value !== null &&
                            value !== undefined,
                    ),
            );
        }
        return data;
    };

    const handleSave = async (inputData: FormValues) => {
        const data = {
            allergies: inputData?.allergies,
            generalAilments: [
                ...(inputData?.generalAilments ?? []),
                ...newGeneralAilments,
            ],
            previousConditions: inputData?.previousConditions,
            surgeryHospitalizations: [
                ...(inputData?.surgeryHospitalizations ?? []),
                ...newSurgeryHospitalizations,
            ],
            communityInterview: [
                ...(inputData?.communityInterview ?? []),
                ...newCommunityInterview,
            ],
            diagnosis: [...(inputData?.diagnosis ?? []), ...newDiagnosis],
            recommendations: [
                ...(inputData?.recommendations ?? []),
                ...newRecommendations,
            ],
            familyHistory: [
                ...(inputData?.familyHistory ?? []),
                ...newFamilyHistory,
            ],
            drugs: [...(inputData?.drugs ?? []), ...newDrugs],
            services: inputData?.services,
        };

        const filteredData = filterEmptyStrings(data);

        try {
            await updateReport({
                variables: {
                    data: filteredData,
                    recordingId: recordingId,
                },
                onCompleted: () => {
                    dispatch(setFormData(null));
                    setNewGeneralAilments([]);
                    setNewSurgeryHospitalizations([]);
                    setNewFamilyHistory([]);
                    setNewDrugs([]);
                    setNewCommunityInterview([]);
                    setNewDiagnosis([]);
                    resetEditStates();
                    getReport({
                        variables: { recordingId: recordingId },
                        fetchPolicy: 'cache-and-network',
                    });
                    notify('Pomyślnie zaktualizowano formularz')[
                        ToastTypes.SUCCESS
                    ]();
                },
                onError: (error) => {
                    return notify('Coś poszło nie tak: ' + error.message)[
                        ToastTypes.ERROR
                    ]();
                },
                refetchQueries: 'active',
            });
        } catch (error) {
            console.error('Update error', error);
        }
    };

    const resetEditStates = () => {
        setEditStates({
            allergies: false,
            communityInterview: false,
            diagnosis: false,
            drugs: false,
            familyHistory: false,
            generalAilments: false,
            patientInfo: false,
            previousConditions: false,
            recommendations: false,
            surgeryHospitalizations: false,
        });
    };

    useEffect(() => {
        getReport();
    }, []);

    return (
        <FormWrapper>
            <FormBox onSubmit={handleSubmit(handleSave)}>
                <CloseIconWrapper>
                    <IconManager
                        name={'Close'}
                        size={36}
                        cursorPointer
                        onClick={() => dispatch(setFormData(null))}
                    />
                </CloseIconWrapper>

                <FormHeaderWrapper>
                    <FormInfoWrapper>
                        <FormInfoInnerWrapper>
                            <FormInfoBlueText>
                                RODZAJ FORMULARZA:{' '}
                            </FormInfoBlueText>
                            <FormInfoText>PEDIATRA</FormInfoText>
                        </FormInfoInnerWrapper>
                        <FormInfoInnerWrapper>
                            <FormInfoBlueText>LEKARZ: </FormInfoBlueText>
                            <FormInfoText>JOLANTA BARANOWSKA</FormInfoText>
                        </FormInfoInnerWrapper>
                        <FormInfoInnerWrapper>
                            <FormInfoBlueText>DATA: </FormInfoBlueText>
                            <FormInfoText>2023-11-11, 12:14:08</FormInfoText>
                        </FormInfoInnerWrapper>
                    </FormInfoWrapper>
                    <FormTitle>FORMULARZ</FormTitle>
                    <FormLogo src={FormLogoImg} />
                </FormHeaderWrapper>

                <FormBodyWrapper>
                    <SectionTitle>Dane pacjenta:</SectionTitle>
                    <InputWrapper>
                        <Input
                            defaultValue={formData?.patientInfo?.name}
                            disabled
                        />
                        <Input
                            defaultValue={formData?.patientInfo?.pesel}
                            disabled
                        />
                    </InputWrapper>

                    <Divider marginTB={30} />

                    <EditableSection
                        title={'Ogólne dolegliwości oraz objawy towarzyszące*'}
                        isEditing={editStates.generalAilments}
                        toggleEditing={() => toggleEditing('generalAilments')}
                    >
                        {formData?.generalAilments?.map(
                            (item: GeneralAilmentFields, idx: number) => (
                                <div key={idx}>
                                    {idx > 0 && <Divider marginTB={15} />}
                                    <SectionSubtitle>
                                        {editStates.generalAilments ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.name}
                                                name={`generalAilments[${idx}].name`}
                                                register={register}
                                            />
                                        ) : (
                                            item?.name
                                        )}
                                    </SectionSubtitle>
                                    <SectionText>
                                        {editStates.generalAilments ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.description}
                                                name={`generalAilments[${idx}].description`}
                                                register={register}
                                            />
                                        ) : (
                                            item?.description
                                        )}
                                    </SectionText>

                                    <Spacing height={20} />

                                    <SymptomsWrapper>
                                        <SectionSubtitle>
                                            Nasilenie:
                                        </SectionSubtitle>
                                        <RadioButton
                                            disabled={
                                                !editStates.generalAilments
                                            }
                                            name={`generalAilments[${idx}].intensity`}
                                            onChange={(value) =>
                                                handleInputChange(
                                                    `generalAilments[${idx}].intensity` as any,
                                                    value,
                                                )
                                            }
                                            options={options}
                                            selectedValue={item?.intensity}
                                        />
                                    </SymptomsWrapper>

                                    <Spacing height={20} />

                                    <SectionSubtitle>
                                        Czas Trwania
                                    </SectionSubtitle>
                                    <SectionText>
                                        {editStates.generalAilments ? (
                                            <AutoResizeTextarea
                                                name={`generalAilments[${idx}].duration`}
                                                register={register}
                                                defaultValue={item?.description}
                                            />
                                        ) : (
                                            item?.duration
                                        )}
                                    </SectionText>
                                </div>
                            ),
                        )}

                        {newGeneralAilments?.map((ailment, index) => (
                            <NewGeneralAilment
                                key={index}
                                index={index}
                                ailment={ailment}
                                onChange={handleNewAilmentChange}
                                onRemove={removeNewAilment}
                                isEdit={editStates.generalAilments}
                            />
                        ))}
                        {editStates.generalAilments && (
                            <AddNewField toggleEditing={addNewAilment} />
                        )}
                    </EditableSection>

                    <EditableSection
                        title={'Choroby przewlekłe/współistniejące*'}
                        isEditing={editStates.previousConditions}
                        toggleEditing={() =>
                            toggleEditing('previousConditions')
                        }
                    >
                        <SymptomsInnerWrapper>
                            <SectionSubtitle>
                                {editStates.previousConditions ? (
                                    <AutoResizeTextarea
                                        defaultValue={
                                            formData?.previousConditions
                                                ?.condition
                                        }
                                        name={`previousConditions.condition`}
                                        register={register}
                                    />
                                ) : (
                                    formData?.previousConditions?.condition
                                )}
                            </SectionSubtitle>
                            <SectionText>
                                {editStates.previousConditions ? (
                                    <AutoResizeTextarea
                                        defaultValue={
                                            formData?.previousConditions
                                                ?.description
                                        }
                                        name={`previousConditions.description`}
                                        register={register}
                                    />
                                ) : (
                                    formData?.previousConditions?.description
                                )}
                            </SectionText>
                        </SymptomsInnerWrapper>
                        <Spacing height={20} />
                        <SymptomsInnerWrapper>
                            <SectionSubtitle>Leki i dawkowanie</SectionSubtitle>
                            <SectionText>
                                {editStates.previousConditions ? (
                                    <AutoResizeTextarea
                                        defaultValue={
                                            formData?.previousConditions
                                                ?.medications
                                        }
                                        name={`previousConditions.medications`}
                                        register={register}
                                    />
                                ) : (
                                    formData?.previousConditions?.medications
                                )}
                            </SectionText>
                        </SymptomsInnerWrapper>
                    </EditableSection>

                    <EditableSection
                        title={'Operacje/hospitalizacje*'}
                        isEditing={editStates.surgeryHospitalizations}
                        toggleEditing={() =>
                            toggleEditing('surgeryHospitalizations')
                        }
                    >
                        {formData?.surgeryHospitalizations?.map(
                            (
                                item: SurgeryHospitalizationsFields,
                                idx: number,
                            ) => (
                                <div key={idx}>
                                    {idx > 0 && <Divider marginTB={15} />}
                                    <SectionSubtitle>
                                        {editStates.surgeryHospitalizations ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.description}
                                                name={`surgeryHospitalizations[${idx}].description`}
                                                register={register}
                                            />
                                        ) : (
                                            item?.description
                                        )}
                                    </SectionSubtitle>

                                    <Spacing height={20} />

                                    <SectionText>
                                        {editStates.surgeryHospitalizations ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.cause}
                                                name={`surgeryHospitalizations[${idx}].cause`}
                                                register={register}
                                            />
                                        ) : (
                                            item?.cause
                                        )}
                                    </SectionText>

                                    <Spacing height={20} />

                                    <SectionSubtitle>
                                        Data / czas trwania
                                    </SectionSubtitle>
                                    <SectionText>
                                        {editStates.surgeryHospitalizations ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.duration}
                                                name={`surgeryHospitalizations[${idx}].duration`}
                                                register={register}
                                            />
                                        ) : (
                                            item?.duration
                                        )}
                                    </SectionText>
                                </div>
                            ),
                        )}

                        {newSurgeryHospitalizations?.map((surgery, index) => (
                            <NewSurgeryHospitalization
                                key={index}
                                index={index}
                                surgery={surgery}
                                onChange={handleNewSurgeryHospitalizationChange}
                                onRemove={removeNewSurgeryHospitalization}
                                isEdit={editStates.surgeryHospitalizations}
                            />
                        ))}
                        {editStates.surgeryHospitalizations && (
                            <AddNewField
                                toggleEditing={addNewSurgeryHospitalization}
                            />
                        )}
                    </EditableSection>

                    <EditableSection
                        title={'Alergie*'}
                        isEditing={editStates.allergies}
                        toggleEditing={() => toggleEditing('allergies')}
                    >
                        <SymptomsInnerWrapper>
                            <SectionSubtitle>
                                {editStates.allergies ? (
                                    <AutoResizeTextarea
                                        defaultValue={formData?.allergies?.name}
                                        name={`allergies.name`}
                                        register={register}
                                    />
                                ) : (
                                    formData?.allergies?.name
                                )}
                            </SectionSubtitle>
                            <SectionText>
                                {editStates.allergies ? (
                                    <AutoResizeTextarea
                                        defaultValue={
                                            formData?.allergies?.description
                                        }
                                        name={`allergies.description`}
                                        register={register}
                                    />
                                ) : (
                                    formData?.allergies?.description
                                )}
                            </SectionText>
                        </SymptomsInnerWrapper>
                        <Spacing height={20} />
                        <SymptomsInnerWrapper>
                            <SectionSubtitle>Leki i dawkowanie</SectionSubtitle>
                            <SectionText>
                                {editStates.allergies ? (
                                    <AutoResizeTextarea
                                        defaultValue={
                                            formData?.allergies?.medications
                                        }
                                        name={`allergies.medications`}
                                        register={register}
                                    />
                                ) : (
                                    formData?.allergies?.medications
                                )}
                            </SectionText>
                        </SymptomsInnerWrapper>
                    </EditableSection>

                    <EditableSection
                        title={'Używki'}
                        isEditing={editStates.drugs}
                        toggleEditing={() => toggleEditing('drugs')}
                    >
                        {formData?.drugs?.map(
                            (item: DrugsFields, idx: number) => (
                                <>
                                    {idx > 0 && <Divider marginTB={15} />}
                                    <SymptomsInnerWrapper>
                                        <SectionSubtitle>
                                            Nazwa używki
                                        </SectionSubtitle>
                                        <SectionText>
                                            {editStates.drugs ? (
                                                <AutoResizeTextarea
                                                    defaultValue={item?.name}
                                                    name={`drugs[${idx}].name`}
                                                    register={register}
                                                />
                                            ) : (
                                                item?.name
                                            )}
                                        </SectionText>
                                    </SymptomsInnerWrapper>

                                    <Spacing height={20} />

                                    <SymptomsInnerWrapper>
                                        <SectionSubtitle>
                                            Częstotliwość
                                        </SectionSubtitle>
                                        <SectionText>
                                            {editStates.drugs ? (
                                                <AutoResizeTextarea
                                                    defaultValue={
                                                        item?.frequency
                                                    }
                                                    name={`drugs[${idx}].frequency`}
                                                    register={register}
                                                />
                                            ) : (
                                                item?.frequency
                                            )}
                                        </SectionText>
                                    </SymptomsInnerWrapper>

                                    <Spacing height={20} />

                                    <SymptomsInnerWrapper>
                                        <SectionSubtitle>Opis</SectionSubtitle>
                                        <SectionText>
                                            {editStates.drugs ? (
                                                <AutoResizeTextarea
                                                    defaultValue={
                                                        item?.description
                                                    }
                                                    name={`drugs[${idx}].description`}
                                                    register={register}
                                                />
                                            ) : (
                                                item?.description
                                            )}
                                        </SectionText>
                                    </SymptomsInnerWrapper>
                                </>
                            ),
                        )}

                        {newDrugs?.map((drug, index) => (
                            <NewDrug
                                key={index}
                                index={index}
                                drug={drug}
                                onChange={handleNewDrugChange}
                                onRemove={removeNewDrug}
                                isEdit={editStates.drugs}
                            />
                        ))}
                        {editStates.drugs && (
                            <AddNewField toggleEditing={addNewDrug} />
                        )}
                    </EditableSection>

                    <EditableSection
                        title={'Choroby w rodzinie*'}
                        isEditing={editStates.familyHistory}
                        toggleEditing={() => toggleEditing('familyHistory')}
                    >
                        {formData?.familyHistory?.map(
                            (item: FamilyHistoryFields, idx: number) => (
                                <>
                                    {idx > 0 && <Divider marginTB={15} />}
                                    <SymptomsInnerWrapper>
                                        <SectionSubtitle>
                                            {editStates.familyHistory ? (
                                                <AutoResizeTextarea
                                                    defaultValue={
                                                        item?.condition
                                                    }
                                                    name={`familyHistory[${idx}].condition`}
                                                    register={register}
                                                />
                                            ) : (
                                                item?.condition
                                            )}
                                        </SectionSubtitle>
                                        <SectionText>
                                            {editStates.familyHistory ? (
                                                <AutoResizeTextarea
                                                    defaultValue={
                                                        item?.description
                                                    }
                                                    name={`familyHistory[${idx}].description`}
                                                    register={register}
                                                />
                                            ) : (
                                                item?.description
                                            )}
                                        </SectionText>
                                    </SymptomsInnerWrapper>

                                    <Spacing height={20} />

                                    <SymptomsInnerWrapper>
                                        <SectionSubtitle>
                                            Stopień pokrewieństwa:
                                        </SectionSubtitle>
                                        {editStates.familyHistory ? (
                                            <Select
                                                name="relationship"
                                                styles={customStyles}
                                                options={relationshipOptions}
                                                defaultValue={relationshipOptions.find(
                                                    (option) =>
                                                        option.value ===
                                                        item.relationship,
                                                )}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        `familyHistory[${idx}].relationship` as any,
                                                        e ? e.value : '',
                                                    )
                                                }
                                            />
                                        ) : (
                                            <SectionText>
                                                {getRelationshipLabel(
                                                    item.relationship,
                                                )}
                                            </SectionText>
                                        )}
                                    </SymptomsInnerWrapper>
                                </>
                            ),
                        )}

                        {newFamilyHistory?.map((family, index) => (
                            <NewFamilyHistory
                                key={index}
                                index={index}
                                family={family}
                                onChange={handleNewFamilyHistoryChange}
                                onRemove={removeNewFamilyHistory}
                                isEdit={editStates.familyHistory}
                            />
                        ))}
                        {editStates.familyHistory && (
                            <AddNewField toggleEditing={addNewFamilyHistory} />
                        )}
                    </EditableSection>

                    <EditableSection
                        title={'Wywiad środowiskowy*'}
                        isEditing={editStates.communityInterview}
                        toggleEditing={() =>
                            toggleEditing('communityInterview')
                        }
                    >
                        {formData?.communityInterview?.map(
                            (item: SingleValueFieldInput, idx: number) => (
                                <InsideWrapper key={idx}>
                                    <SectionText>
                                        {editStates.communityInterview ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.details}
                                                name={`communityInterview[${idx}].details`}
                                                register={register}
                                                placeholder={
                                                    'Dodaj informację środowiskową'
                                                }
                                            />
                                        ) : (
                                            item?.details
                                        )}
                                    </SectionText>
                                </InsideWrapper>
                            ),
                        )}

                        {newCommunityInterview?.map((item, index) => (
                            <InsideWrapper key={index}>
                                <SectionText>
                                    <StyledInput
                                        defaultValue={item.details}
                                        onChange={(e) =>
                                            handleNewCommunityInterviewChange(
                                                index,
                                                e.target.value,
                                            )
                                        }
                                        placeholder="Dodaj informację środowiskową"
                                    />
                                </SectionText>
                            </InsideWrapper>
                        ))}
                        {editStates.communityInterview && (
                            <AddNewField
                                toggleEditing={addNewCommunityInterview}
                            />
                        )}
                    </EditableSection>

                    <Divider marginTB={30} />

                    <EditableSection
                        title={'Diagnoza*'}
                        isEditing={editStates.diagnosis}
                        toggleEditing={() => toggleEditing('diagnosis')}
                    >
                        {formData?.diagnosis?.map(
                            (item: SingleValueFieldInput, idx: number) => (
                                <InsideWrapper key={idx}>
                                    <SectionText>
                                        {editStates.diagnosis ? (
                                            <AutoResizeTextarea
                                                defaultValue={item?.details}
                                                name={`diagnosis[${idx}].details`}
                                                placeholder={'Dodaj diagnozę'}
                                                register={register}
                                            />
                                        ) : (
                                            item?.details
                                        )}
                                    </SectionText>
                                </InsideWrapper>
                            ),
                        )}

                        {newDiagnosis?.map((item, index) => (
                            <InsideWrapper key={index}>
                                <SectionText>
                                    <StyledInput
                                        value={item.details}
                                        onChange={(e) =>
                                            handleNewDiagnosisChange(
                                                index,
                                                e.target.value,
                                            )
                                        }
                                        placeholder="Dodaj diagnozę"
                                    />
                                </SectionText>
                            </InsideWrapper>
                        ))}
                        {editStates.diagnosis && (
                            <AddNewField toggleEditing={addNewDiagnosis} />
                        )}
                    </EditableSection>

                    <EditableSection
                        title={'Zalecenia*'}
                        isEditing={editStates.recommendations}
                        toggleEditing={() => toggleEditing('recommendations')}
                    >
                        {formData?.recommendations?.map(
                            (
                                recommendation: RecommendationFields,
                                index: number,
                            ) => (
                                <div key={index}>
                                    {index > 0 && <Divider marginTB={15} />}
                                    <List>
                                        {recommendation?.items?.map(
                                            (item, idx) =>
                                                editStates.recommendations ? (
                                                    <AutoResizeTextarea
                                                        key={idx}
                                                        defaultValue={item}
                                                        name={`recommendations[${index}].items[${idx}]`}
                                                        placeholder={
                                                            'Dodaj zalecenia'
                                                        }
                                                        register={register}
                                                    />
                                                ) : (
                                                    <ListItem key={idx}>
                                                        {item}
                                                    </ListItem>
                                                ),
                                        )}
                                    </List>
                                    <SectionSubtitle>
                                        Leki i dawkowanie
                                    </SectionSubtitle>
                                    <List>
                                        {recommendation?.medications?.map(
                                            (medication, idx) =>
                                                editStates.recommendations ? (
                                                    <AutoResizeTextarea
                                                        key={idx}
                                                        defaultValue={
                                                            medication
                                                        }
                                                        name={`recommendations[${index}].medications[${idx}]`}
                                                        register={register}
                                                        placeholder={
                                                            'Dodaj leki i dawkowanie'
                                                        }
                                                    />
                                                ) : (
                                                    <ListItem key={idx}>
                                                        {medication}
                                                    </ListItem>
                                                ),
                                        )}
                                    </List>
                                </div>
                            ),
                        )}

                        {newRecommendations?.map((recommendation, index) => (
                            <NewRecommendation
                                key={index}
                                index={index}
                                recommendation={recommendation}
                                onChange={handleNewRecommendationChange}
                                onRemove={removeNewRecommendation}
                                isEdit={editStates.recommendations}
                            />
                        ))}
                        {editStates.recommendations && (
                            <AddNewField toggleEditing={addNewRecommendation} />
                        )}
                    </EditableSection>

                    <EditableSection
                        title={'Wystawione dokumenty'}
                        isEditing={editStates.issuedDocuments}
                        toggleEditing={() => toggleEditing('issuedDocuments')}
                    >
                        <CheckboxWrapper>
                            <Checkbox
                                label="e-prescriptions"
                                checked={watchEprescriptions}
                                onChange={handleCheckboxChange(
                                    'services.eprescriptions',
                                )}
                                disabled={!editStates.issuedDocuments}
                            />
                            <Checkbox
                                label="e-referrals"
                                checked={watchEreferrals}
                                onChange={handleCheckboxChange(
                                    'services.ereferrals',
                                )}
                                disabled={!editStates.issuedDocuments}
                            />
                            <Checkbox
                                label="e-zla"
                                checked={watchEzla}
                                onChange={handleCheckboxChange('services.ezla')}
                                disabled={!editStates.issuedDocuments}
                            />
                        </CheckboxWrapper>
                    </EditableSection>

                    <StyledButton
                        type="submit"
                        disabled={formState.isSubmitting}
                    >
                        Zapisz
                    </StyledButton>
                </FormBodyWrapper>
            </FormBox>
        </FormWrapper>
    );
};
