import { SubTitle } from 'components/_universal/Typography.styled';
import styled from 'styled-components';

export const CheckboxWrapper = styled.label<{ disabled?: boolean }>`
    align-items: center;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    gap: 8px;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const CheckboxStyled = styled.input`
    display: none;
`;

export const Label = styled(SubTitle)`
    font-size: 16px;
`;
