import { IconManager } from 'components/_universal/IconManager/IconManager';
import { DrugsFields } from 'domains/Form/types';
import React from 'react';
import { SectionSubtitle, SectionText, StyledInput } from '../../Form.styled';
import {
    BlueBackground,
    RemoveButtonWrapper,
    SymptomsInnerWrapper,
    SymptomsWrapper,
} from '../Styles';

interface NewDrugProps {
    index: number;
    drug: DrugsFields;
    onChange: (index: number, field: keyof DrugsFields, value: any) => void;
    onRemove: (index: number) => void;
    isEdit: boolean;
}

const NewDrug: React.FC<NewDrugProps> = ({
    index,
    drug,
    onChange,
    onRemove,
    isEdit,
}) => {
    return (
        <SymptomsWrapper isEdit={isEdit}>
            <BlueBackground>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Nazwa używki</SectionSubtitle>
                    <SectionText>
                        {isEdit ? (
                            <StyledInput
                                value={drug.name}
                                onChange={(e) =>
                                    onChange(index, 'name', e.target.value)
                                }
                            />
                        ) : (
                            drug.name
                        )}
                    </SectionText>
                </SymptomsInnerWrapper>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Częstotliwość</SectionSubtitle>
                    <SectionText>
                        {isEdit ? (
                            <StyledInput
                                value={drug.frequency}
                                onChange={(e) =>
                                    onChange(index, 'frequency', e.target.value)
                                }
                            />
                        ) : (
                            drug.frequency
                        )}
                    </SectionText>
                </SymptomsInnerWrapper>
                <SymptomsInnerWrapper>
                    <SectionSubtitle>Opis</SectionSubtitle>
                    <SectionText>
                        {isEdit ? (
                            <StyledInput
                                value={drug.description}
                                onChange={(e) =>
                                    onChange(
                                        index,
                                        'description',
                                        e.target.value,
                                    )
                                }
                            />
                        ) : (
                            drug.description
                        )}
                    </SectionText>
                </SymptomsInnerWrapper>
            </BlueBackground>

            {isEdit && (
                <RemoveButtonWrapper>
                    <IconManager
                        onClick={() => onRemove(index)}
                        name={'TrashIcon'}
                    />
                </RemoveButtonWrapper>
            )}
        </SymptomsWrapper>
    );
};

export default NewDrug;
